// Core variables and mixins
@import "variables.less";
@import "mixins.less";

//
// Forms
// --------------------------------------------------

.add-btn-group {
	padding: 4px;
}

label {
	font-weight: 600;
}

.label-field {
	display: block;
	min-height: 20px;

	&.left {
		display: inline-block;
		float: left;
	}

	&.right {
		display: inline-block;
		float: right;
	}
}

.label-text {
	font-weight: bold;
	font-size: 14px;
	min-height: 24px;
}

.form-control {
	background-color: @input-bg;
	border-radius: 0;
	.box-shadow(inset 0 1px 1px rgba(0,0,0,.025));

	&:hover {
		border-color: darken(@input-border, 10%);
		.box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
	}
	// Customize the `:focus` state to imitate native WebKit styles.
	.form-control-focus();
	// Disabled and read-only inputs
	// Note: HTML5 says that inputs under a fieldset > legend:first-child won't be
	//   disabled if the fieldset is disabled. Due to implementation difficulty,
	//   we don't honor that edge case; we style them as disabled anyway.
	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		cursor: not-allowed;
		background-color: transparent;
		border: 1px solid transparent;
		border-bottom-color: lighten(@input-border, 15%);
	}
}

.form-horizontal {
	.form-group {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: @form-group-margin-bottom;
	}

	.form-control-static {
		padding-top: 7px;
		padding-bottom: 7px;
	}
}


.input-sm {
	font-size: ceil(@font-size-base * .92); // ~13px
}

.global-search {
	float: right;
	margin-top: 3px;
	width: 300px;
	min-width: 300px;
}

@media screen and (max-width: 1060px) {
	.global-search {
		width: 200px;
		min-width: 200px;
	}
}

form {
	.required label {
		font-weight: bold;

		&:after {
			color: red;
			content: ' *';
			display: inline;
		}
	}

	.field-validation-error {
		color: red;
	}
}

.input-align-right {
	text-align: right;
}

.account-container {
	width: 390px;
	display: block;
	margin: 60px auto 21px auto;
	background: #f9f9f9;
	border: 1px solid #d5d5d5;

	.content {
		padding: 16px 28px 23px;

		h1 {
			margin-bottom: .4em;
			color: #333;
			font-size: 32px;
		}

		.login-fields {

			.field {
				margin-bottom: 1.25em;

			}

			label {
				font-weight: normal;
			}

			input {
				font-family: 'Open Sans', sans-serif;
				font-size: 13px;
				color: #333;
				background-color: #fdfdfd;
				width: 100%;
				display: block;
				margin: 0;
				box-shadow: inset 2px 2px 4px #f1f1f1;
				height: 40px;
				-webkit-box-shadow: 0 0 0 30px white inset !important;
			}

			.error-box {
				margin: 0;
			}
		}

		.login-actions {
			float: left;
			width: 100%;
			margin-top: -1em;
			margin-bottom: 1.25em;

			.btn {
				float: right;
				margin-top: 18px;
			}
		}

		span.login-checkbox {
			float: left;
			margin-top: 28px;
		}

		.login-recover {
			float: left;
			width: 100%;
			margin: 10px 0 0;

			a {
				font-size: 1em;
				text-align: center;
			}
		}
	}
}

@media only screen and (min-width : 481px) {
	.account-container {
		position: fixed;
		margin: 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}


@media (max-width: 480px) {

	.account-container {
		width: auto;
		margin-top: 35px;
		margin-left: auto;
	}

	.login-fields input {
		width: 160px;
	}

	.register .login-fields input {
		width: 204px;
		padding-left: 6px;
	}
}

div[uib-datepicker] {
	:focus {
		outline: 0;
	}

	table {
		border: 0;
		display: table;
		width: 100%;

		tbody {
			padding-left: 20px;
			display: table-row-group;

			tr {
				display: table-row;

				td {
					padding: 0;
					display: table-cell;
					width: auto;
					border: none;

					.btn {
						border: none;
					}

					.btn-info {
						color: #333;
						background-color: #fff;
						border-color: #ccc;
					}

					.btn-info:focus,
					.btn-info.focus {
						color: #333;
						background-color: #e6e6e6;
						border-color: #8c8c8c;
					}

					.btn-info:hover {
						color: #333;
						background-color: #e6e6e6;
						border-color: #adadad;
					}

					.btn-info:active,
					.btn-info.active,
					.open > .dropdown-toggle.btn-info {
						color: #333;
						background-color: #e6e6e6;
						border-color: #adadad;
						background-image: none;
					}

					.btn-info:active:hover,
					.btn-info.active:hover,
					.open > .dropdown-toggle.btn-info:hover,
					.btn-info:active:focus,
					.btn-info.active:focus,
					.open > .dropdown-toggle.btn-info:focus,
					.btn-info:active.focus,
					.btn-info.active.focus,
					.open > .dropdown-toggle.btn-info.focus {
						color: #333;
						background-color: #d4d4d4;
						border-color: #8c8c8c;
					}

					.btn-info.disabled:hover,
					.btn-info[disabled]:hover,
					fieldset[disabled] .btn-info:hover,
					.btn-info.disabled:focus,
					.btn-info[disabled]:focus,
					fieldset[disabled] .btn-info:focus,
					.btn-info.disabled.focus,
					.btn-info[disabled].focus,
					fieldset[disabled] .btn-info.focus {
						background-color: #fff;
						border-color: #ccc;
					}

					.btn-info .badge {
						color: #fff;
						background-color: #333;
					}
				}
			}
		}

		thead {
			padding-left: 20px;
			display: table-header-group;
			//width: 100%;
			tr:first-child th {
				//padding-left: 10px;
				border: none;
			}

			strong {
				padding: 0 13px;
			}

			th {
				display: table-cell;
				width: auto !important;
			}
		}
	}
}

.footer-button-container {
	float:right;

	button{
		margin-left:5px;
	}
}
