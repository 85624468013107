@import "variables.less";
//
// Mixins
// --------------------------------------------------


// Utilities
// -------------------------

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
.clearfix() {
    &:before,
    &:after {
        display: table; /* 2 */
        content: " "; /* 1 */
    }

    &:after {
        clear: both;
    }
}

// Webkit-style focus
.tab-focus() {
    // Default
    outline: thin dotted #333;
    // Webkit
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

// Center-align a block level element
.center-block() {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Sizing shortcuts
.size(@width; @height) {
    width: @width;
    height: @height;
}

.square(@size) {
    .size(@size; @size);
}

// Placeholder text
.placeholder(@color: @input-color-placeholder) {
    &:-moz-placeholder {
        color: @color;
    }
    // Firefox 4-18
    &::-moz-placeholder {
        color: @color;
    }
    // Firefox 19+
    &:-ms-input-placeholder {
        color: @color;
    }
    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: @color;
    }
    // Safari and Chrome
}

// Text overflow
// Requires inline-block or block for proper styling
.text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// CSS image replacement
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
.hide-text() {
    font: ~"0/0" a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}



// CSS3 PROPERTIES
// --------------------------------------------------

// CSS3 PROPERTIES
// --------------------------------------------------

// Border Radius
.border-radius(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

// Single Corner Border Radius
.border-top-left-radius(@radius) {
    -webkit-border-top-left-radius: @radius;
    -moz-border-radius-topleft: @radius;
    border-top-left-radius: @radius;
}

.border-top-right-radius(@radius) {
    -webkit-border-top-right-radius: @radius;
    -moz-border-radius-topright: @radius;
    border-top-right-radius: @radius;
}

.border-bottom-right-radius(@radius) {
    -webkit-border-bottom-right-radius: @radius;
    -moz-border-radius-bottomright: @radius;
    border-bottom-right-radius: @radius;
}

.border-bottom-left-radius(@radius) {
    -webkit-border-bottom-left-radius: @radius;
    -moz-border-radius-bottomleft: @radius;
    border-bottom-left-radius: @radius;
}


// Single side border-radius
.border-top-radius(@radius) {
    border-top-right-radius: @radius;
    border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
    border-bottom-right-radius: @radius;
    border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
    border-bottom-left-radius: @radius;
    border-top-left-radius: @radius;
}

// Drop shadows
.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
    box-shadow: @shadow;
}

.box-shadow(@shadow, @shadow) {
    -webkit-box-shadow: @shadow, @shadow;
    box-shadow: @shadow, @shadow;
}

// Transitions
.transition(@transition) {
    -webkit-transition: @transition;
    transition: @transition;
}

.transition-delay(@transition-delay) {
    -webkit-transition-delay: @transition-delay;
    transition-delay: @transition-delay;
}

.transition-duration(@transition-duration) {
    -webkit-transition-duration: @transition-duration;
    transition-duration: @transition-duration;
}

.transition-transform(@transition) {
    -webkit-transition: -webkit-transform @transition;
    -moz-transition: -moz-transform @transition;
    -o-transition: -o-transform @transition;
    transition: transform @transition;
}

// Transformations
.rotate(@degrees) {
    -webkit-transform: rotate(@degrees);
    -ms-transform: rotate(@degrees);
    transform: rotate(@degrees);
}

.scale(@ratio) {
    -webkit-transform: scale(@ratio);
    -ms-transform: scale(@ratio);
    transform: scale(@ratio);
}

.translate(@x; @y) {
    -webkit-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y);
    transform: translate(@x, @y);
}

.skew(@x; @y) {
    -webkit-transform: skew(@x, @y);
    -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twbs/bootstrap/issues/4885
    transform: skew(@x, @y);
}

.translate3d(@x; @y; @z) {
    -webkit-transform: translate3d(@x, @y, @z);
    transform: translate3d(@x, @y, @z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
.backface-visibility(@visibility) {
    -webkit-backface-visibility: @visibility;
    -moz-backface-visibility: @visibility;
    backface-visibility: @visibility;
}

// Background clipping
.background-clip(@clip) {
    background-clip: @clip;
}

// Background sizing
.background-size(@size) {
    background-size: @size;
}

// Box sizing
.box-sizing(@boxmodel) {
    -webkit-box-sizing: @boxmodel;
    -moz-box-sizing: @boxmodel;
    box-sizing: @boxmodel;
}

// Box shadow
.box-shadow-important(@shadow) {
    -webkit-box-shadow: @shadow !important;
    -moz-box-shadow: @shadow !important;
    box-shadow: @shadow !important;
}


// User select
// For selecting text on the page
.user-select(@select) {
    -webkit-user-select: @select;
    -moz-user-select: @select;
    -ms-user-select: @select;
    -o-user-select: @select;
    user-select: @select;
}

// Resize anything
.resizable(@direction) {
    resize: @direction; // Options: horizontal, vertical, both
    overflow: auto; // Safari fix
}

// CSS3 Content Columns
.content-columns(@column-count; @column-gap: @grid-gutter-width) {
    -webkit-column-count: @column-count;
    -moz-column-count: @column-count;
    column-count: @column-count;
    -webkit-column-gap: @column-gap;
    -moz-column-gap: @column-gap;
    column-gap: @column-gap;
}

// Optional hyphenation
.hyphens(@mode: auto) {
    word-wrap: break-word;
    -webkit-hyphens: @mode;
    -moz-hyphens: @mode;
    -ms-hyphens: @mode;
    -o-hyphens: @mode;
    hyphens: @mode;
}

// Opacity
.opacity(@opacity) {
    opacity: @opacity;
    // IE8 filter
    @opacity-ie: (@opacity * 100);
    filter: ~"alpha(opacity=@{opacity-ie})";
}



// GRADIENTS
// --------------------------------------------------

#gradient {
    // Horizontal gradient, from left to right
    //
    // Creates two color stops, start and end, by specifying a color and position for each color stop.
    // Color stops are not available in IE9 and below.
    .horizontal(@start-color: #555; @start-percent: 0%; @end-color: #333; @end-percent: 100%) {
        background-color: @end-color;
        background-image: -webkit-gradient(linear, @start-percent top, @end-percent top, from(@start-color), to(@end-color)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(left, color-stop(@start-color @start-percent), color-stop(@end-color @end-percent)); // Safari 5.1+, Chrome 10+
        background-image: -moz-linear-gradient(left, @start-color @start-percent, @end-color @end-percent); // FF 3.6+
        background-image: linear-gradient(to right, @start-color @start-percent, @end-color @end-percent); // Standard, IE10
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@start-color),argb(@end-color))); // IE9 and down
    }
    // Vertical gradient, from top to bottom
    //
    // Creates two color stops, start and end, by specifying a color and position for each color stop.
    // Color stops are not available in IE9 and below.
    .vertical(@start-color: #555; @start-percent: 0%; @end-color: #333; @end-percent: 100%) {
        background-color: @end-color;
        background-image: -webkit-gradient(linear, left @start-percent, left @end-percent, from(@start-color), to(@end-color)); // Safari 4+, Chrome 2+
        background-image: -webkit-linear-gradient(top, @start-color, @start-percent, @end-color, @end-percent); // Safari 5.1+, Chrome 10+
        background-image: -moz-linear-gradient(top, @start-color @start-percent, @end-color @end-percent); // FF 3.6+
        background-image: linear-gradient(to bottom, @start-color @start-percent, @end-color @end-percent); // Standard, IE10
        background-repeat: repeat-x;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@start-color),argb(@end-color))); // IE9 and down
    }

    .directional(@start-color: #555; @end-color: #333; @deg: 45deg) {
        background-color: @end-color;
        background-repeat: repeat-x;
        background-image: -webkit-linear-gradient(@deg, @start-color, @end-color); // Safari 5.1+, Chrome 10+
        background-image: -moz-linear-gradient(@deg, @start-color, @end-color); // FF 3.6+
        background-image: linear-gradient(@deg, @start-color, @end-color); // Standard, IE10
    }

    .horizontal-three-colors(@start-color: #00b3ee; @mid-color: #7a43b6; @color-stop: 50%; @end-color: #c3325f) {
        background-color: mix(@mid-color, @end-color, 80%);
        background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from(@start-color), color-stop(@color-stop, @mid-color), to(@end-color));
        background-image: -webkit-linear-gradient(left, @start-color, @mid-color @color-stop, @end-color);
        background-image: -moz-linear-gradient(left, @start-color, @mid-color @color-stop, @end-color);
        background-image: linear-gradient(to right, @start-color, @mid-color @color-stop, @end-color);
        background-repeat: no-repeat;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",argb(@start-color),argb(@end-color))); // IE9 and down, gets no color-stop at all for proper fallback
    }

    .vertical-three-colors(@start-color: #00b3ee; @mid-color: #7a43b6; @color-stop: 50%; @end-color: #c3325f) {
        background-color: mix(@mid-color, @end-color, 80%);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@start-color), color-stop(@color-stop, @mid-color), to(@end-color));
        background-image: -webkit-linear-gradient(@start-color, @mid-color @color-stop, @end-color);
        background-image: -moz-linear-gradient(top, @start-color, @mid-color @color-stop, @end-color);
        background-image: linear-gradient(@start-color, @mid-color @color-stop, @end-color);
        background-repeat: no-repeat;
        filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@start-color),argb(@end-color))); // IE9 and down, gets no color-stop at all for proper fallback
    }

    .radial(@inner-color: #555; @outer-color: #333) {
        background-color: @outer-color;
        background-image: -webkit-gradient(radial, center center, 0, center center, 460, from(@inner-color), to(@outer-color));
        background-image: -webkit-radial-gradient(circle, @inner-color, @outer-color);
        background-image: -moz-radial-gradient(circle, @inner-color, @outer-color);
        background-image: radial-gradient(circle, @inner-color, @outer-color);
        background-repeat: no-repeat;
    }

    .striped(@color: #555; @angle: 45deg) {
        background-color: @color;
        background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
        background-image: -webkit-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
        background-image: -moz-linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
        background-image: linear-gradient(@angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
    }
}

// Reset filters for IE
//
// When you need to remove a gradient background, don't forget to use this to reset
// the IE filter for IE9 and below.
.reset-filter() {
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}



// RETINA IMAGE SUPPORT
// --------------------------------------------------

// Short retina mixin for setting background-image and -size
.img-retina(@file-1x; @file-2x; @width-1x; @height-1x) {
    background-image: url("@{file-1x}");

    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( min--moz-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2/1), only screen and ( min-device-pixel-ratio: 2), only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx) {
        background-image: url("@{file-2x}");
        background-size: @width-1x @height-1x;
    }
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.

.img-responsive(@display: block;) {
    display: @display;
    max-width: 100%; // Part 1: Set a maximum relative to the parent
    height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}


// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
.nav-divider(@color: #e5e5e5) {
    height: 1px;
    margin: ((@line-height-computed / 2) - 1) 0;
    overflow: hidden;
    background-color: @color;
}

// Panels
// -------------------------
.panel-variant(@border; @heading-text-color; @heading-bg-color; @heading-border;) {
    border-color: @border;

    & > .panel-heading {
        color: @heading-text-color;
        background-color: @heading-bg-color;
        border-color: @heading-border;

        + .panel-collapse .panel-body {
            border-top-color: @border;
        }
    }

    & > .panel-footer {
        + .panel-collapse .panel-body {
            border-bottom-color: @border;
        }
    }
}


// Alerts
// -------------------------
.alert-variant(@background; @border; @text-color) {
    background-color: @background;
    border-color: @border;
    color: @text-color;

    hr {
        border-top-color: darken(@border, 5%);
    }

    .alert-link {
        color: darken(@text-color, 10%);
    }
}

// Tables
// -------------------------
.table-row-variant(@state; @background; @border) {
    // Exact selectors below required to override `.table-striped` and prevent
    // inheritance to nested tables.
    .table > thead > tr,
    .table > tbody > tr,
    .table > tfoot > tr {
        > td.@{state},
        > th.@{state},
        &.@{state} > td,
        &.@{state} > th {
            background-color: @background;
            border-color: @border;
        }
    }
    // Hover states for `.table-hover`
    // Note: this is not available for cells or rows within `thead` or `tfoot`.
    .table-hover > tbody > tr {
        > td.@{state}:hover,
        > th.@{state}:hover,
        &.@{state}:hover > td {
            background-color: darken(@background, 5%);
            border-color: darken(@border, 5%);
        }
    }
}

// Button pseudo states
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
.button-variant(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:hover,
    &:focus,
    &:active,
    &.active,
    .open .dropdown-toggle& {
        color: @color;
        background-color: darken(@background, 8%);
        border-color: darken(@border, 12%);
    }

    &:active,
    &.active,
    .open .dropdown-toggle& {
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: @background;
            border-color: @border;
        }
    }
}

.btn-pseudo-states(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: darken(@background, 5%);
        border-color: darken(@border, 10%);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: @background;
            border-color: @border;
        }
    }

    &:active,
    &.active,
    .open .dropdown-toggle& {
        background-image: none;
    }
}

// Button sizes
// -------------------------
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
    padding: @padding-vertical @padding-horizontal;
    font-size: @font-size;
    line-height: @line-height;
    border-radius: @border-radius;
}

// Pagination
// -------------------------
.pagination-size(@padding-vertical; @padding-horizontal; @font-size; @border-radius) {
    > li {
        > a,
        > span {
            padding: @padding-vertical @padding-horizontal;
            font-size: @font-size;
        }

        &:first-child {
            > a,
            > span {
                .border-left-radius(@border-radius);
            }
        }

        &:last-child {
            > a,
            > span {
                .border-right-radius(@border-radius);
            }
        }
    }
}

// Labels
// -------------------------
.label-variant(@color) {
    background-color: @color;

    &[href] {
        &:hover,
        &:focus {
            background-color: darken(@color, 10%);
        }
    }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.
.navbar-vertical-align(@element-height) {
    margin-top: ((@navbar-height - @element-height) / 2);
    margin-bottom: ((@navbar-height - @element-height) / 2);
}

// Progress bars
// -------------------------
.progress-bar-variant(@color) {
    background-color: @color;

    .progress-striped & {
        #gradient > .striped(@color);
    }
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
.responsive-visibility() {
    display: block !important;

    tr& {
        display: table-row !important;
    }

    th&,
    td& {
        display: table-cell !important;
    }
}

.responsive-invisibility() {
    display: none !important;

    tr& {
        display: none !important;
    }

    th&,
    td& {
        display: none !important;
    }
}

// Grid System
// -----------

// Centered container element
.container-fixed() {
    margin-right: auto;
    margin-left: auto;
    .clearfix();
}

// Creates a wrapper for a series of columns
.make-row() {
    // Then clear the floated columns
    .clearfix();

    @media (min-width: @screen-small) {
        margin-left: (@grid-gutter-width / -2);
        margin-right: (@grid-gutter-width / -2);
    }
    // Negative margin nested rows out to align the content of columns
    .row {
        margin-left: (@grid-gutter-width / -2);
        margin-right: (@grid-gutter-width / -2);
    }
}

// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    float: left;
    width: percentage((@columns / @grid-columns));
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);
}

// Generate the columns
.make-column(@columns) {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
    // Calculate width based on number of columns available
    @media (min-width: @grid-float-breakpoint) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

// Generate the large column offsets
.make-md-column-offset(@columns) {
    @media (min-width: @screen-md) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-md-column-push(@columns) {
    @media (min-width: @screen-md) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-md-column-pull(@columns) {
    @media (min-width: @screen-md) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@gutter / 2);
    padding-right: (@gutter / 2);
    // Calculate width based on number of columns available
    @media (min-width: @screen-lg) {
        float: left;
        width: percentage((@columns / @grid-columns));
    }
}

// Generate the large column offsets
.make-lg-column-offset(@columns) {
    @media (min-width: @screen-lg) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-lg-column-push(@columns) {
    @media (min-width: @screen-lg) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-lg-column-pull(@columns) {
    @media (min-width: @screen-lg) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the column offsets
.make-column-offset(@columns) {
    @media (min-width: @grid-float-breakpoint) {
        margin-left: percentage((@columns / @grid-columns));
    }
}

.make-column-push(@columns) {
    @media (min-width: @grid-float-breakpoint) {
        left: percentage((@columns / @grid-columns));
    }
}

.make-column-pull(@columns) {
    @media (min-width: @grid-float-breakpoint) {
        right: percentage((@columns / @grid-columns));
    }
}

// Generate the small columns
.make-small-column(@columns) {
    position: relative;
    float: left;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: (@grid-gutter-width / 2);
    padding-right: (@grid-gutter-width / 2);
    @max-width: (@grid-float-breakpoint - 1);

    // Calculate width based on number of columns available
    @media (max-width: @max-width) {
        width: percentage((@columns / @grid-columns));
    }
}


.make-md-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;

  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding

  padding-left:  (@gutter / 2);

  padding-right: (@gutter / 2);


  // Calculate width based on number of columns available

  @media (min-width: @screen-md) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

.form-control-validation(@text-color: #555; @border-color: #ccc; @background-color: #f5f5f5) {
    // Color the label and help text
    .help-block,
    .control-label {
        color: @text-color;
    }
    // Set the border and box shadow on specific inputs to match
    .form-control {
        border-color: @border-color;
        .box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
        &:focus {
            border-color: darken(@border-color, 10%);
            @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@border-color, 20%);
            .box-shadow(@shadow);
        }
    }
    // Set validation states also for addons
    .input-group-addon {
        color: @text-color;
        border-color: @border-color;
        background-color: @background-color;
    }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

.form-control-focus(@color: @input-border-focus) {
    @color-rgba: rgba(red(@color), green(@color), blue(@color), .6);

    &:focus,
    &:active {
        border-color: @color;
        outline: 0;
        .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.1), 0 0 8px @{color-rgba}");
    }
}




// CUSTOM RESET
// -------------------------------------------------------------------------------------------------------------
#reset {
    .box-shadow-none() {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }

    .border-radius-none() {
        border-radius: 0 !important;
    }
}

// Font Icon
// -------------------------
.font-appicon(@font-size: 1em, @line-height: 1, @font: @font-family-appicon) {
    font-family: @font;
    speak: none;
    font-size: @font-size;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: @line-height;
    -webkit-font-smoothing: antialiased;
}

// Animation
.animation(@animation) {
    -webkit-animation: @animation;
    -moz-animation: @animation;
    -o-animation: @animation;
    animation: @animation;
}

.animation-delay(@animation-delay) {
    -webkit-animation-delay: @animation-delay;
    -moz-animation-delay: @animation-delay;
    -o-animation-delay: @animation-delay;
    animation-delay: @animation-delay;
}

.animation-duration(@animation-duration) {
    -webkit-animation-duration: @animation-duration;
    -moz-animation-duration: @animation-duration;
    -o-animation-duration: @animation-duration;
    animation-duration: @animation-duration;
}

.animation-iteration-count(@animation-iteration-count) {
    -webkit-animation-iteration-count: @animation-iteration-count;
    -moz-animation-iteration-count: @animation-iteration-count;
    -o-animation-iteration-count: @animation-iteration-count;
    animation-iteration-count: @animation-iteration-count;
}
