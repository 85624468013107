// Core variables and mixins
@import "variables.less";
@import "mixins.less";

//
// Buttons
// --------------------------------------------------

.btn {
  position: relative;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.53846154;

  &:not(.btn-link) {
    .material-icons {
      font-size: 16px;
      vertical-align: top;
      margin-top: 2px;
    }
  }
}

.material-icons {
  &.icon-right {
    margin-left: 5px;
  }

  &.icon-left {
    margin-right: 5px;
  }
}

.btn-link {
  color: #1E64E1;
}


// Info appears as blue-green
.btn-outline {
  &:hover,
  &:focus {
    color: @text-color;
  }

  &.br-strong {
    padding: (@padding-base-vertical - 1) @padding-base-horizontal;
    border-width: 2px;
  }
}

.btn-clouds {
  .btn-pseudo-states(#444; #f6f6f6; #ddd
  );
}

.btn-sm,
.btn-xs {
  font-size: ceil(@font-size-base * .92); // ~13px
}

// Reposition the caret correction space
.btn .caret {
  &.ml {
    margin-left: 5px;
  }

  &.mr {
    margin-right: 5px;
    margin-left: 0;
  }
}

.btn-scroll-up {
  border: none;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 11;
}
