// Core variables and mixins
@import "variables.less";
@import "mixins.less";

.no-padding {
  padding: 0 !important;
}

.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-padding-h-r {
  padding-right: 0 !important;
}

.no-padding-h-l {
  padding-left: 0 !important;
}

.no-padding-v {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-padding-v-t {
  padding-top: 0 !important;
}

.no-padding-v-b {
  padding-bottom: 0 !important;
}

.padding-10 {
  padding: 10px;
}

.padding-10-h {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-15-h {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-0-h {
  padding-left: 0;
  padding-right: 0;
}

.padding-10-v {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-15-v {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-20 {
  padding: 20px;
}

.padding-20-v {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-5-v-t {
  padding-top: 5px;
}

.padding-10-v-t {
  padding-top: 10px;
}

.padding-20-v-t {
  padding-top: 20px;
}

.padding-10-v-b {
  padding-bottom: 10px;
}

.padding-left-15{
  padding-left: 15px;
}

.margin-10 {
  margin: 10px;
}

.margin-10-r {
  margin-right: 10px;
}

.margin-5-l {
  margin-left: 5px;
}

.margin-10-l {
  margin-left: 10px;
}

.margin-20-l {
  margin-left: 20px;
}

.margin-10-v {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-5-v-t {
  margin-top: 5px;
}

.margin-10-v-t {
  margin-top: 10px;
}

.margin-20-v-t {
  margin-top: 20px;
}

.margin-20-v-t {
  margin-top: 20px;
}

.margin-10-v-b {
  margin-bottom: 10px;
}

.margin-20-v-b {
  margin-bottom: 20px;
}

.margin-n-10-h-l {
  margin-left: -10px;
}

.margin-top-30{
  margin-top: 30px;
}

.margin-right-n-10{
  margin-right: -10px;
}

.mt10 {
  margin-top: 10px;
}

.ml10 {
  margin-left: 10px;
}

.margin-left-n-5{
  margin-left: -5px;
}

.margin-bottom-5{
  margin-bottom: 5px;
}

.no-border {
  border: none !important;
}

.font-size-20{
  font-size: 20px !important;
}

.font-size-14{
  font-size: 14px !important;
}

.font-size-12{
  font-size: 12px !important;
}

.width-12-p{
  width: 12% !important;
}

.width-20-p{
  width: 20% !important;
}

.width-23-p{
  width: 23% !important;
}

.width-25-p{
  width: 25% !important;
}

.width-35-p{
  width: 35% !important;
}

.width-101-p{
  width: 101% !important;
}

.height-272{
  height: 272px !important;
}

.height-615{
  height: 615px !important;
}

.height-100-p{
  height: 100% !important;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.text-align-left{
  text-align: left !important;
}

.text-align-right{
  text-align: right !important;
}

.border-none{
  border: none !important;
}

.opacity-0-6{
  opacity: 0.6 !important;
}

.resize-none{
  resize: none !important;
}

.position-relative{
  position: relative !important;
}

.overflow-visible{
  overflow: visible !important;
}

.font-weight-600{
  font-weight: 600 !important;
}

.fa-1-1x{
  font-size: 1.1em !important;
}

.control-group {
  position: relative;
  display: inline-block;
}

.accent {
  color: @brand-primary-accent !important;

  a &,
  .open &,
  &:hover,
  &:active {
    color: @brand-primary-accent !important;
  }
}

.sidebar-open .left-sidebar {
  left: 0;
}

//  Heading
// ----------------------------------------
.heading {
  position: relative;

  &[data-icon]:before {
    display: inline-block;
    margin-right: 10px;
    width: auto;
    height: auto;
    line-height: normal;
    vertical-align: baseline;
    font-size: .8421em;
    font-style: normal;
    text-decoration: inherit;
    .opacity(.2);
  }
}

.panel-tabbable {
  padding: 0;
}

.panel-footer {
  background-color: transparent;
}

.page-heading,
.page-control {
  margin-bottom: @line-height-computed / 2;
}

.page-content {
  .page-crop {
    max-width: 1280px;
  }
}

.page-control-bar .btn-toolbar {
  float: left;
}


.contact-form {
  margin-left: 60px;


  .contact-names {
    margin: 15px 0 0;
  }

  .contact-actions {
    .btn-toolbar {
      margin-top: 3px;
      margin-right: 20px;
    }

    .update-date {
      margin-left: 20px;
      margin-top: 14px;
    }
  }
}

.contact-profiles {
  position: relative;

  .profile-list {
    position: absolute;
    margin-top: 50px;
    padding-left: 0;
    width: 60px;
    list-style: none;

    .profile-card {
      min-height: 50px;
      margin-right: -1px;
      margin-bottom: 5px;
      display: block;
      padding: 4px;
      border: 1px solid #DDDDDD;

      &.active {
        a {
          opacity: 0.8;
        }

        &:hover {
          a {
            opacity: 0.9;
          }
        }

        border-right: 1px solid #ffffff;
      }

      &:hover {
        a {
          opacity: 0.5;
        }
      }

      a {
        opacity: 0.2;
        width: 42px;
        height: 42px;

        &:link {
          text-decoration: none;
        }

        &:visited {
          text-decoration: none;
        }

        &:hover {
          text-decoration: none;
        }

        &:active {
          text-decoration: none;
        }
      }
    }
  }

  .profile-content {
    margin: 10px 0 10px 60px;
    display: block;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    min-height: 300px;

    .primary-indicator {
      cursor: pointer;
    }

    .profile-title {
      font-size: 18px;
      font-weight: 500;
      margin-left: 30px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .profile-role {
      margin-left: 30px;
    }
  }
}

.org-form {
  margin-left: 75px;
  padding-right: 15px;

  .contact-names {
    margin: 10px 10px 0 10px;
  }

  .contact-actions {
    .btn-toolbar {
      margin-top: 3px;
      margin-right: 20px;
    }

    .update-date {
      margin-left: 20px;
      margin-top: 14px;
    }
  }
}

.org-roles {
  position: relative;

  .roles-list {
    position: absolute;
    margin-top: 50px;
    padding-left: 0;
    width: 60px;
    list-style: none;

    .role-card {
      min-height: 50px;
      margin-right: -1px;
      margin-bottom: 5px;
      display: block;
      padding: 4px;
      border: 1px solid #DDDDDD;
      border-right: 1px solid #fff;

      &.active {
        a {
          opacity: 0.8;
        }

        &:hover {
          a {
            opacity: 0.9;
          }
        }

        border-right: 1px solid #ffffff;
      }

      &:hover {
        a {
          opacity: 0.5;
        }
      }

      a {
        opacity: 0.2;
        width: 42px;
        height: 42px;

        &:link {
          text-decoration: none;
        }

        &:visited {
          text-decoration: none;
        }

        &:hover {
          text-decoration: none;
        }

        &:active {
          text-decoration: none;
        }
      }
    }
  }

  .role-content {
    margin: 10px 0 10px 60px;
    display: block;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    min-height: 300px;

    .primary-indicator {
      cursor: pointer;
    }

    .role-title {
      font-size: 18px;
      font-weight: 500;
      margin-left: 30px;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}


.print-button {
  display: block;
}


.timesheet-entry {
  .ts-header {
    display: block;
    border-bottom: 1px solid #DDDDDD;

    .workorders-label {
      float: left !important;
      padding-top: 15px;
      margin-right: 5px;
    }

    .workorders-selection {
      padding: 8px 0;
    }

    .ts-navigation {
      width: 100%;
      padding: 8px;

      .ts-title {
        float: right;
        margin-right: 10px;
        font-weight: 400;
      }

      .btn-group {
        float: right;
      }
    }
  }

  .ts-print-header,
  .ts-print-footer,
  .ts-print-date-range {
    display: none;
  }


  .ts-details {
    margin-top: 15px;

    .ts-datepicker {
      padding: 8px;
    }

    .ts-calendar {
      padding: 15px 8px 8px 8px;
    }

    .ts-summary {
      padding: 8px;

      .panel-group {
        .profile-list.panel {
          margin-top: 0;
          border-radius: 0;
        }

        .panel-default {
          background-color: #fff;

          .panel-heading {
            background-color: #fff;
            height: 50px;
            padding: 15px;

            .panel-title {
              text-transform: uppercase;

              .panel-header {
                font-weight: bold;
                text-decoration: none;
              }

              .panel-header-link {
                cursor: pointer;
              }
            }

            .total-hours {
              font-size: 27px;
              line-height: 15px;
              font-weight: 400;
            }
          }

          .panel-collapse {
            .panel-body {
              border-top-color: #FF9900;

              .label-summary {
                padding-left: 15px;
                color: #999999;
                font-weight: normal;
                margin-bottom: 0;
              }

              .label-summary-details {
                padding-left: 15px;
                margin-bottom: 5px;
                color: #000;
              }
            }
          }

          .label-document-list {
            padding-left: 15px;
            color: #999999;
            font-weight: normal;
            margin-bottom: 0;
            margin-top: 10px;
          }

          .label-document-list-details {
            padding-left: 15px;
            font-weight: normal;
            margin-bottom: 0;
          }

          .document-delete, .document-delete i {
            color: #000;
            cursor: pointer;
          }

          .label-document-list-link {
            padding-left: 25px;
          }
        }
      }

      .ts-status {
        float: right;
        margin-right: 10px;
      }

      .ts-total {
        float: right;
        margin-right: 10px;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
}

.make-icon(@iconSize) {
  -moz-background-size: @iconSize @iconSize;
  -o-background-size: @iconSize @iconSize;
  -webkit-background-size: @iconSize @iconSize;
  background-size: @iconSize @iconSize;
  height: @iconSize;
  width: @iconSize;
  display: inline-block;
  float: left;
}

.icon {
  margin-right: 1em;
  zoom: 1;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  .make-icon(30px);

  &.icon-large {
    .make-icon(36px);
  }

  &.icon-small {
    .make-icon(16px);
  }

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .nav-list & {
    margin-left: 3px;
    margin-right: .3em;
    padding: 0;
    margin-top: -4px;
    margin-bottom: 4px;
    height: 30px;
    width: 30px;
    opacity: 0.75;
    padding-right: 5px;

    &:hover {
      opacity: 1;
    }
  }

  &.icon-organization-client {
    .make-icon(18px);
    background-image: url(../assets/images/icons/icon-organization-client.png);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px !important;
  }

  &.icon-organization-independentcontractor,
  &.icon-organization-limitedliabilitycompany,
  &.icon-organization-subvendor {
    .make-icon(18px);
    background-image: url(../assets/images/icons/icon-organization-independentcontractor.png);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px !important;
  }

  &.icon-organization-internal {
    .make-icon(18px);
    background-image: url(../assets/images/icons/icon-organization-internal.png);
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px !important;
  }

  &.icon-organizational-profile {
    background: url(../assets/images/icons/icon-organizational-profile.png) no-repeat center;
  }
  &.icon-internal-profile {
    background: url(../assets/images/icons/icon-internal-profile.png) no-repeat center;
  }
  &.icon-worker-profile {
    background: url(../assets/images/icons/icon-worker-profile.png) no-repeat center;
  }
}

.detail-container.in {
  width: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  opacity: .5;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.detail-container.out {
  display: none;
}

.slider-holder {
  border: 1px solid #354051;
  background-color: white;
  top: @navbar-mh;
  width: 60%;
  height: calc(~"100vh - " @navbar-mh);
  display: block;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
  right: -3000px;
  opacity: 1;
  position: fixed;
}

.slider-holder.out {
  -webkit-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: -3000px;
}

.slider-holder.in {
  -webkit-transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: 0;
  opacity: 1;
}

.slider-view-invoice {
  border: 1px solid black;
  background-color: white;
  top: @navbar-mh;
  width: 1200px;
  height: 95%;
  display: block;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
  right: -3000px;
  opacity: 1;
  position: fixed;
}

.slider-view-invoice.out {
  -webkit-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: -3000px;
}

.slider-view-invoice.in {
  -webkit-transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: 5px;
  opacity: 1;
}

.slider-view-compliance-document {
  border: 1px solid black;
  background-color: white;
  top: @navbar-mh;
  width: 1000px;
  height: 95%;
  display: block;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
  right: -3000px;
  opacity: 1;
  position: fixed;

  .slider-view-compliance-document-pdf-container {
    height: 600px;
    width: 840px;
    margin-left: 5%;
    position: relative;
  }
}

.slider-view-compliance-document.out {
  -webkit-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: -3000px;
}

.slider-view-compliance-document.in {
  -webkit-transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: 5px;
  opacity: 1;
}

.transaction-summary-panel {
  background-color: #FAFAFA;
  border-radius: 0;
  margin: 10px;

  a {
    line-height: 30px;
  }
}


.tabbable-left {

  .tabs-below > .nav-tabs,
  .tabs-right > .nav-tabs,
  .tabs-left > .nav-tabs {
    border-bottom: 0;
  }

  .tab-content > .tab-pane,
  .pill-content > .pill-pane {
    display: none;
  }

  .tab-content > .active,
  .pill-content > .active {
    display: block;
  }

  .tabs-below > .nav-tabs {
    border-top: 1px solid #ddd;
  }

  .tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
  }

  .tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }

  .tabs-below > .nav-tabs > li > a:hover,
  .tabs-below > .nav-tabs > li > a:focus {
    border-top-color: #ddd;
    border-bottom-color: transparent;
  }

  .tabs-below > .nav-tabs > .active > a,
  .tabs-below > .nav-tabs > .active > a:hover,
  .tabs-below > .nav-tabs > .active > a:focus {
    border-color: transparent #ddd #ddd #ddd;
  }

  .tabs-left > .nav-tabs > li,
  .tabs-right > .nav-tabs > li {
    float: none;
  }

  .tabs-left > .nav-tabs > li > a,
  .tabs-right > .nav-tabs > li > a {
    min-width: 74px;
    margin-right: 0;
    margin-bottom: 3px;
  }

  .tabs-left > .nav-tabs {
    float: left;
    border-right: 1px solid #ddd;
  }

  .tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }

  .tabs-left > .nav-tabs > li > a:hover,
  .tabs-left > .nav-tabs > li > a:focus {
    border-color: #eeeeee #dddddd #eeeeee #eeeeee;
  }

  .tabs-left > .nav-tabs .active > a,
  .tabs-left > .nav-tabs .active > a:hover,
  .tabs-left > .nav-tabs .active > a:focus {
    border-color: #ddd transparent #ddd #ddd;
    *border-right-color: #ffffff;
  }

  .tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
    border-left: 1px solid #ddd;
  }

  .tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
  }

  .tabs-right > .nav-tabs > li > a:hover,
  .tabs-right > .nav-tabs > li > a:focus {
    border-color: #eeeeee #eeeeee #eeeeee #dddddd;
  }

  .tabs-right > .nav-tabs .active > a,
  .tabs-right > .nav-tabs .active > a:hover,
  .tabs-right > .nav-tabs .active > a:focus {
    border-color: #ddd #ddd #ddd transparent;
    *border-left-color: #ffffff;
  }
}

.btn-assignment-task {
  margin-left: 5px;
  color: #ffffff;
  width: 90px;
}

.btn-assignment-task.approve {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-assignment-task.submit {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-assignment-task.decline {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-assignment-task.withdraw {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-assignment-task.recall {
  background-color: #d9534f;
  border-color: #d43f3a;
}


.assignment-workorders {
  margin-bottom: 20px;
  padding-left: 0;


  .assignment-workorders-header {
    display: block;
    padding: 5px;
    text-transform: uppercase;
  }

  .assignment-workorder-item {
    position: relative;
    display: block;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    margin-bottom: 10px;

    .workorder-item {
      border-bottom: 2px solid @accent-color;
      padding-bottom: 10px;

      .workorder-id {
        font-size: 16px;
      }
    }

    .assignment-workorderversion-item {
      position: relative;
      display: block;
      padding: 5px 10px;
      margin-bottom: -1px;
      background-color: #ffffff;
      border: 1px solid #dddddd;
    }

    div.workorder-version-item {
      cursor: pointer;
      padding: 5px;
      margin: 5px 0;

      .small-text {
        color: #333333;
        font-size: 11px;
      }

      &.active {
        background-color: #ebebeb;
      }
    }

    a.assignment-workorderversion-item {
      color: #555555;
    }

    a.assignment-workorderversion-item .assignment-workorderversion-item-heading {
      color: #333333;
    }

    a.assignment-workorderversion-item:hover,
    a.assignment-workorderversion-item:focus {
      text-decoration: none;
      background-color: #f5f5f5;
    }

    .assignment-workorderversion-item.active,
    .assignment-workorderversion-item.active:hover,
    .assignment-workorderversion-item.active:focus {
      z-index: 2;
      background-color: #ececec; /*#428bca;*/
    }

    .assignment-workorderversion-item.active .assignment-workorderversion-item-heading,
    .assignment-workorderversion-item.active:hover .assignment-workorderversion-item-heading,
    .assignment-workorderversion-item.active:focus .assignment-workorderversion-item-heading {
      color: inherit;
    }

    .assignment-workorderversion-item.active .assignment-workorderversion-item-text,
    .assignment-workorderversion-item.active:hover .assignment-workorderversion-item-text,
    .assignment-workorderversion-item.active:focus .assignment-workorderversion-item-text {
      color: #e1edf7;
    }

    .assignment-workorderversion-item-heading {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .assignment-workorderversion-item-text {
      margin-bottom: 0;
      line-height: 1.3;
    }
  }

  .assignment-workorder-item.active,
  .assignment-workorder-item.active:hover,
  .assignment-workorder-item.active:focus {
    z-index: 2;
    background-color: #fafafa;
  }

  .assignment-workorder-item.active .assignment-workorder-item-heading,
  .assignment-workorder-item.active:hover .assignment-workorder-item-heading,
  .assignment-workorder-item.active:focus .assignment-workorder-item-heading {
    color: inherit;
  }

  .assignment-workorder-item.active .assignment-workorder-item-text,
  .assignment-workorder-item.active:hover .assignment-workorder-item-text,
  .assignment-workorder-item.active:focus .assignment-workorder-item-text {
    color: #e1edf7;
  }
}

.btn-group {
  margin-right: 10px;
}

.to-uppercase {
  text-transform: uppercase;
}

.label-to-right {
  padding: 6px 15px 0 7px;
  text-align: right;
}

.label-to-same-padding {
  padding: 6px 15px 0 7px;
}

.with-border-bottom {
  border-bottom: 1px solid #ddd;
}

.with-background {
  background-color: #f9f9f9;
}

.without-margin-bottom {
  margin-bottom: 0 !important;
}

div.div-placeholder {
  opacity: 0.1 !important;
  filter: alpha(opacity=10) !important; /* msie  */
}

.contact-versions {
  margin-bottom: 20px;
  padding-left: 0;
  margin-top: 25px;

  .contact-version-group {
    position: relative;
    display: block;
    background-color: #ffffff;
    margin-bottom: 10px;

    div.contact-version-item {
      cursor: pointer;
      padding: 5px;
      margin: 5px 0;

      .small-text {
        color: #333333;
        font-size: 11px;
      }

      &.active {
        background-color: #ebebeb;
      }
    }
  }

  .contact-version-group.active,
  .contact-version-group.active:hover,
  .contact-version-group.active:focus {
    z-index: 2;
    background-color: #fafafa;
  }
}

.payrolltax-versions {
  margin-bottom: 20px;
  padding-left: 0;

  .payrolltax-versions-header {
    display: block;
    padding: 5px;
    height: 35px;
    text-transform: uppercase;
  }

  .payrolltax-version-group {
    position: relative;
    display: block;
    padding: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;

    .version-item {
      border-bottom: 2px solid @accent-color;
      padding-bottom: 10px;

      .version-id {
        font-size: 16px;
      }
    }

    div.version-version-item {
      cursor: pointer;
      padding: 5px;
      margin: 5px 0;

      .small-text {
        color: #333333;
        font-size: 11px;
      }

      &.active {
        background-color: #ebebeb;
      }
    }
  }

  .payrolltax-version-group.active,
  .payrolltax-version-group.active:hover,
  .payrolltax-version-group.active:focus {
    z-index: 2;
    background-color: #fafafa;
  }

  .payrolltax-version-group.active .payrolltax-version-group-heading,
  .payrolltax-version-group.active:hover .payrolltax-version-group-heading,
  .payrolltax-version-group.active:focus .payrolltax-version-group-heading {
    color: inherit;
  }

  .payrolltax-version-group.active .payrolltax-version-group-text,
  .payrolltax-version-group.active:hover .payrolltax-version-group-text,
  .payrolltax-version-group.active:focus .payrolltax-version-group-text {
    color: #e1edf7;
  }
}

.commissionrate-versions {
  margin-bottom: 20px;
  padding-left: 0;

  .commissionrate-versions-header {
    display: block;
    padding: 5px;
    height: 35px;
    text-transform: uppercase;
  }

  .commissionrate-version-group {
    position: relative;
    display: block;
    padding: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;

    .version-item {
      border-bottom: 2px solid @accent-color;
      padding-bottom: 10px;

      .version-id {
        font-size: 16px;
      }
    }

    div.version-version-item {
      cursor: pointer;
      padding: 5px;
      margin: 5px 0;

      .small-text {
        color: #333333;
        font-size: 11px;
      }

      &.active {
        background-color: #ebebeb;
      }
    }
  }

  .commissionrate-version-group.active,
  .commissionrate-version-group.active:hover,
  .commissionrate-version-group.active:focus {
    z-index: 2;
    background-color: #fafafa;
  }

  .commissionrate-version-group.active .commissionrate-version-group-heading,
  .commissionrate-version-group.active:hover .commissionrate-version-group-heading,
  .commissionrate-version-group.active:focus .commissionrate-version-group-heading {
    color: inherit;
  }

  .commissionrate-version-group.active .commissionrate-version-group-text,
  .commissionrate-version-group.active:hover .commissionrate-version-group-text,
  .commissionrate-version-group.active:focus .commissionrate-version-group-text {
    color: #e1edf7;
  }
}

.slider-view-advance {
  border: 1px solid black;
  background-color: white;
  top: 2px;
  max-width: 1000px;
  height: 99.8%;
  display: block;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
  right: -3000px;
  opacity: 1;
  position: fixed;

  &.out {
    -webkit-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    right: -3000px;
  }

  &.in {
    -webkit-transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    right: 5px;
    opacity: 1;
  }
}

.slider-view-garnishee {
  border: 1px solid black;
  background-color: white;
  top: 2px;
  max-width: 1000px;
  height: 99.8%;
  display: block;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
  right: -3000px;
  opacity: 1;
  position: fixed;
  padding-left: 15px;
  padding-right: 15px;

  &.out {
    -webkit-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    right: -3000px;
  }

  &.in {
    -webkit-transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    right: 5px;
    opacity: 1;
  }
}

.slider-view-paymentreleaseschedule {
  border: 1px solid black;
  background-color: white;
  top: 50px;
  width: 900px;
  height: 94.5%;
  display: block;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: hidden;
  right: -3000px;
  opacity: 1;
  position: fixed;

  &.out {
    -webkit-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    right: -3000px;
  }

  &.in {
    -webkit-transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    right: 5px;
    opacity: 1;
  }
}

.email-add-button {
  padding: 7px 9px;
  font-size: 12px;

  .email-add-button-icon {
    font-size: 17px;
    font-weight: bold;
    vertical-align: middle;
  }
}

.organization-versions {
  margin-bottom: 20px;
  padding-left: 0;

  .organization-versions-header {
    display: block;
    padding: 5px;
    height: 35px;
    text-transform: uppercase;
  }

  .organization-version-group {
    position: relative;
    display: block;
    padding: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;

    .version-item {
      border-bottom: 2px solid @accent-color;
      padding-bottom: 10px;

      .version-id {
        font-size: 16px;
      }
    }

    div.version-version-item {
      cursor: pointer;
      padding: 5px;

      .small-text {
        color: #333333;
        font-size: 11px;
      }

      &.active {
        background-color: #ebebeb;
      }
    }
  }

  .organization-version-group.active,
  .organization-version-group.active:hover,
  .organization-version-group.active:focus {
    z-index: 2;
    background-color: #fafafa;
  }

  .organization-version-group.active .organization-version-group-heading,
  .organization-version-group.active:hover .organization-version-group-heading,
  .organization-version-group.active:focus .organization-version-group-heading {
    color: inherit;
  }

  .organization-version-group.active .organization-version-group-text,
  .organization-version-group.active:hover .organization-version-group-text,
  .organization-version-group.active:focus .organization-version-group-text {
    color: #e1edf7;
  }
}

.complianceDocumentRule-versions {
  margin-bottom: 20px;
  padding-left: 0;

  .complianceDocumentRule-versions-header {
    display: block;
    padding: 5px;
    height: 35px;
    text-transform: uppercase;
  }

  .complianceDocumentRule-version-group {
    position: relative;
    display: block;
    padding: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;

    .version-item {
      border-bottom: 2px solid @accent-color;
      padding-bottom: 10px;

      .version-id {
        font-size: 16px;
      }
    }

    div.version-version-item {
      cursor: pointer;
      padding: 5px;
      margin: 5px 0;

      .small-text {
        color: #333333;
        font-size: 11px;
      }

      &.active {
        background-color: #ebebeb;
      }
    }
  }

  .complianceDocumentRule-version-group.active,
  .complianceDocumentRule-version-group.active:hover,
  .complianceDocumentRule-version-group.active:focus {
    z-index: 2;
    background-color: #fafafa;
  }

  .complianceDocumentRule-version-group.active .complianceDocumentRule-version-group-heading,
  .complianceDocumentRule-version-group.active:hover .complianceDocumentRule-version-group-heading,
  .complianceDocumentRule-version-group.active:focus .complianceDocumentRule-version-group-heading {
    color: inherit;
  }

  .complianceDocumentRule-version-group.active .complianceDocumentRule-version-group-text,
  .complianceDocumentRule-version-group.active:hover .complianceDocumentRule-version-group-text,
  .complianceDocumentRule-version-group.active:focus .complianceDocumentRule-version-group-text {
    color: #e1edf7;
  }
}

.pickadate {
  font-family: 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif;

  a {
    &:visited {
      color: #666666;
    }

    color: #666666;
  }
}

.pickadate-header {
  position: relative;
}

.pickadate-main {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.pickadate-cell {
  overflow: hidden;
  margin: 0;
  padding: 0;

  li {
    display: block;
    float: left;
    border: 1px solid #DCDCDC;
    border-width: 0 1px 1px 0;
    width: 14.285%;
    padding: 1.3% 0 1.3% 0;
    line-height: normal;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    &:nth-child(7n+0) {
      border-right: 1px solid #DCDCDC;
    }

    &:nth-child(1), &:nth-child(8), &:nth-child(15), &:nth-child(22), &:nth-child(29), &:nth-child(36) {
      border-left: 1px solid #DCDCDC;
    }
  }

  .pickadate-disabled {
    color: #DCDCDC;

    a {
      color: #DCDCDC;
    }
  }

  .pickadate-enabled {
    cursor: pointer;
    font-size: 12px;
    color: #666666;
  }

  .pickadate-today {
    background-color: #eaeaea;
  }

  .pickadate-active {
    background-color: #b52a00;
    color: white;
  }

  .pickadate-head {
    border-top: 1px solid #DCDCDC;
    background: #f3f3f3;

    &:nth-child(1), &:nth-child(7) {
      background: #f3f3f3;
    }
  }
}

.pickadate-centered-heading {
  font-weight: normal;
  text-align: center;
  font-size: 1em;
  margin: 13px 0 13px 0;
  line-height: normal;
}

.pickadate-controls {
  position: absolute;
  z-index: 10;
  width: 100%;

  .pickadate-next {
    float: right;
  }

  a {
    text-decoration: none;
    font-size: 0.9em;
  }
}

.pickadate-modal {
  position: absolute;
  background-color: #fff;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 5px 5px 5px;
  z-index: 1000;
}

.btn-primary {
  color: #fff;
  background-color: @btn-primary-bg;
  border-color: @btn-primary-border;
}


.no-margin {
  margin: 0 !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.btn-primary:hover {
  background-color: @btn-primary-hover-bg;
  border-color: @btn-primary-hover-border;
}

.alignment-on-title {
  margin-left: 20px;
  margin-top: 20px;
}

.panel-alignment-bottom-right-button {
  margin-top: 10px;
  margin-right: 15px;
}

.btn .badge {
  top: 0;
}

.modal-phoenix {
  .modal-dialog {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 3%;
    @media (min-width: 1120px) {
      margin-left: calc(~'(48% - 255px)');
    }
  }
}

.warning-visual-cue {
  color: red;
  font-size: 14px;
  font-weight: 600;

  .material-icons {
    position: relative;
    top: 4px;
    padding-left: 18px;
    padding-right: 9px;
  }
}

body > div[uib-datepicker-popup-wrap] > ul.uib-datepicker-popup {
  z-index: 1100;
}

.clear-timesheet-body {
  min-height: 30vh;
  font-size: 14px;
}

.modal-dialog.form-entry-modal-content {

  width: 432px;

  .form-group {
    float: left;
    width: 100%;
    margin-bottom: 12px;
  }

  .modal-body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .modal-body::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  .modal-body::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
    border: 2px solid transparent;
    border-radius: 15px;
  }
}

@media screen and (max-width: 767px) {

  .modal-dialog.form-entry-modal-content {
    border-radius: 0;
    border: none;
    max-width: 100%;
    width: 100%;
    margin: auto;
  }
}

.custom-form-group-phone{
  .dx-dropdowneditor-button{
    width: 20px;
    min-width: 20px;
  }
  .profile-phone-ext{
    font-weight: 600;
    padding-top: 8px!important
  }
} 

.flex-row{
  display:flex;
  flex-direction: row;
}

.info-container {
  margin: 5px !important;
  width:100%;
  &.alert-info {
      display: inline-block;
      .row {
          padding-right: 40px;
      }
  }
}

.compliance-doc-accordian-group{
  background-color:#FCFBF9;
  border:1px solid #ddd !important;
  border-radius: 4px !important;
  padding: 6px 0 !important;
  position:relative;
}

.closed-compliance-doc-accordian-group{
  box-shadow: none !important;
}

.gvlogo-for-toast{
  position:relative;
  left:-30px;
  display:flex;
  flex-direction: row;
  width :320px;

  .gv-logo-container{
    padding:0 20px 0 10px;
    width:50px;
    img{
      width:30px;
      height:30px;
      animation: rotate 0.75s infinite;
    }  
  }

  @keyframes rotate {
    100% {
        transform: rotateY(180deg);
    }
}
  .text-container{
    width:260px;
  }
}
