@import "../../../less/variables.less";

.padding-left-10 {
  padding-left: 10px;
}

.padding-l-0,
.padding-lr-0 {
  padding-left: 0 !important;
}

.padding-r-0,
.padding-lr-0 {
  padding-right: 0 !important;
}

.dx-texteditor {
  padding: 1px;
}

.dx-header-row .dx-datagrid-text-content {
  font-weight: 600;
  color      : #333333;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td[role="columnheader"],
.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row>td.dx-command-select,
.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row>td.dx-command-expand,
.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row>td.dx-command-adaptive {
  border-bottom: 2px solid #999999;
}

.dx-datagrid .dx-row>td {
  padding    : 8px;
  line-height: 1.9;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row:hover>td {
  cursor          : pointer;
  background-color: #dfdfdf !important;
  color           : #000 !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.testRowClass>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.testRowClass>td {
  background-color: @table-test-warning  !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.testRowClass:hover>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.testRowClass:hover>td,
.dx-row-alt.dx-data-row.testRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-row.dx-data-row.testRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.testRowClass.dx-selection>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.testRowClass.dx-selection>td {
  background-color: @table-test-warning-interact  !important;
  border-color    : @table-test-warning-interact  !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.pendingRowClass>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.pendingRowClass>td {
  background-color: #dff0d8 !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.pendingRowClass:hover>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.pendingRowClass:hover>td,
.dx-row-alt.dx-data-row.pendingRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-row.dx-data-row.pendingRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.pendingRowClass.dx-selection>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.pendingRowClass.dx-selection>td {
  background-color: #dff0d8 !important;
  border-color    : #dff0d8 !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.conflictRowClass>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.conflictRowClass>td {
  background-color: #fcf8e3 !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.conflictRowClass:hover>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.conflictRowClass:hover>td,
.dx-row-alt.dx-data-row.conflictRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-row.dx-data-row.conflictRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.conflictRowClass.dx-selection>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.conflictRowClass.dx-selection>td {
  background-color: #fcf8e3 !important;
  border-color    : #fcf8e3 !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.discardRowClass>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.discardRowClass>td {
  background-color: #f2dede !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.discardRowClass:hover>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.discardRowClass:hover>td,
.dx-row-alt.dx-data-row.discardRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-row.dx-data-row.discardRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.discardRowClass.dx-selection>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.discardRowClass.dx-selection>td {
  background-color: #f2dede !important;
  border-color    : #f2dede !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.completeRowClass>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.completeRowClass>td {
  background-color: #d9edf7 !important;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.completeRowClass:hover>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.completeRowClass:hover>td,
.dx-row-alt.dx-data-row.completeRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-row.dx-data-row.completeRowClass.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row.dx-data-row.completeRowClass.dx-selection>td,
.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-table tr.dx-row-alt.dx-data-row.completeRowClass.dx-selection>td {
  background-color: #d9edf7 !important;
  border-color    : #d9edf7 !important;
}

.dx-row-alt.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused),
.dx-row.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused) {
  background-color: #dfdfdf !important;
}

.dx-datagrid-header-panel {
  border-bottom: none;
  overflow: initial !important;
}

.dx-loadindicator {
  width: 64px !important;
}

.dx-datagrid-rowsview .dx-row>.dx-master-detail-cell {
  padding: 15px 15px 15px 0;
}

.dx-datagrid-search-panel {
  margin: 0;

  @media only screen and (max-width: 768px) {
    width: 120px !important;
  }
}

.dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button {
  margin: 0;
}

.dx-field-item-content.dx-adaptive-item-text {
  white-space: pre-wrap;
}

.dx-texteditor-buttons-container {
  .dx-clear-button-area {
    .dx-icon-clear:before {
      content: "\F00A";
    }
  }

  .dx-button.material-icons {
    width  : 32px;
    padding: 0;

    .dx-button-content {
      padding: 6px;
    }
  }
}

app-phx-select {
  ng-select {
    div.ui-select-container {
      .btn {
        font-weight: inherit !important;
      }

      .btn-link {
        i.glyphicon-remove {
          font-family: "Material Icons";
          font-size  : @font-size-large;
          color      : @btn-default-color;

          &:before {
            content: "clear";
          }
        }
      }

      .ui-select-choices-row.active a.dropdown-item {
        color           : inherit !important;
        background-color: #f5f5f5;
      }

      .ui-select-choices-row a.dropdown-item:hover {
        background-color: #f5f5f5;
      }
    }
  }
}

app-phx-select span.ui-select-placeholder {
  white-space  : nowrap;
  text-overflow: ellipsis;
  overflow     : hidden;
  display      : block;
  width        : calc(~"100% - 10px");
}

app-phx-select span.ui-select-match-text {
  white-space  : nowrap;
  text-overflow: ellipsis;
  overflow     : hidden;
  display      : block;
  width        : calc(~"100% - 30px");
  color        : @gray;
}

// custom visibility for list item templates by state
.dx-list .dx-list-item {
  &:not(.dx-state-hover) {
    .phx-dx-hover-only {
      display: none;
    }
  }
}

.dx-calendar-body td {
  padding: 0;
}

.dx-calendar-cell {
  border: none;

  .highlight-day {
    padding-top: 6px;
    display    : block;
    height     : 100%;
  }

  &.dx-calendar-selected-date {
    color: #333;

    &.dx-calendar-today {
      font-weight: bold;
      color      : #333;
    }
  }

  &.dx-calendar-empty-cell {
    background: none;

    &.dx-calendar-other-month {
      opacity: 0;
    }
  }
}

.ui-select-choices-row>a {
  display: grid !important;
}

.nowrap {
  white-space: nowrap;
}

.flex-row {
  display        : flex;
  flex-flow      : row wrap;
  justify-content: space-between;
  -ms-flex-direction: row !important;

  &__child {
    flex-grow : 1;
    flex-basis: 50%;
  }
}

.milestone-icon {
  display        : inline-block;
  vertical-align : top;
  background-size: 16px;
  width          : 16px;
  height         : 16px;
  margin-top     : 1px;

  &.current {
    background-image: var(--fbo-theme-timeline-current-icon);
  }

  &.future {
    background-image: url("./../../../assets/timeline/status_future.svg");
  }
}

.milestone-separator {
  display   : inline-block;
  width     : 32px;
  height    : 1px;
  border    : 0;
  border-top: 1px solid #c4c4c4;
  margin    : 5px;
  padding   : 0;
}

.current-status {
  cursor: pointer;

  div {
    display    : inline-block;
    white-space: normal;
    word-break : break-word;
  }
}

.future-status div {
  display    : inline-block;
  color      : #5e6e90 !important;
  white-space: normal;
  word-break : break-word;
}

.modal-dialog {
  .modal-footer {
    background: transparent;
  }
}

.btn-group>.btn:not(.btn-tab):not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 4px;
}

.btn-group>.btn:not(.btn-tab):last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(.btn-tab):not(:first-child),
.btn-group>.btn:not(.btn-tab):first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius    : 4px;
  border-bottom-left-radius : 4px;
  border-top-right-radius   : 4px;
  border-bottom-right-radius: 4px;
}

.timesheet-tooltip {
  min-width: 250px;

  .tooltip-inner {
    max-width       : 250px;
    color           : #1e2430;
    border-radius   : 0 !important;
    border          : 1px solid #ccc;
    box-shadow      : -1px 0 5px rgba(0, 0, 0, 0.15) !important;
    background-color: #fff !important;
    padding         : 10px;
  }

  .tooltip-arrow {
    border-top-color: #ccc !important;
  }
}

.primary-button {
  background   : @primary-color;
  border-radius: 2px;
  font-weight  : 500;
  font-size    : 14px;
  color        : #ffffff;
  border       : 1px solid @primary-color;
  opacity      : 1 !important;
  min-width    : 120px;

  &:hover,
  &:focus {
    color  : #ffffff;
    opacity: 0.9 !important;
  }
}

.secondary-button {
  background   : #ffffff;
  border-radius: 2px;
  font-weight  : 500;
  font-size    : 14px;
  color        : #1a203f;
  border       : 1px solid #bdc5d5;
  opacity      : 1 !important;
  min-width    : 120px;

  &:hover,
  &:focus {
    color  : #1a203f;
    opacity: 0.9 !important;
  }
}

.primary-button[disabled],
.secondary-button[disabled] {
  background-color: rgba(242, 243, 247, 0.75) !important;
  cursor          : pointer !important;
  pointer-events  : none !important;
  border          : 1px solid #bdc5d5;
  color           : #1a203f;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink      : 1 !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction    : column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction    : row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction    : column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap    : wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap    : nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap    : wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex    : 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow        : 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow        : 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink      : 0 !important;
}

.justify-content-start {
  -ms-flex-pack  : start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack  : end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack  : center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack  : justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack  : distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items   : flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items   : flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items   : center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items   : baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items   : stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content     : flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content     : flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content     : center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content     : space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content     : space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content     : stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self         : auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self         : flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self         : flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self         : center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self         : baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self         : stretch !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grabbing,
.drag-active-cursor * {
  cursor: grabbing !important;
}

.loading-bar {
  position: relative;
  overflow: hidden;

  &::after {
    content         : "";
    display         : block;
    background-color: #dddfe2;
    position        : absolute;
    top             : 0;
    bottom          : 0;
    width           : 100%;
    height          : 100%;
    transform       : translateX(0);
    animation       : 1.5s loading-placeholder ease-in-out infinite;
  }
}

.modal-dialog.modal-large {
  width: 1050px;
}

.keyboard-arrow-right {
  vertical-align: middle;
  display       : inline-block;
  color         : #aaa;
}

@keyframes loading-placeholder {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

// Extra material design icon styles
.material-icons, .material-icons-outlined {
  &.md-18 {
    font-size: 18px !important;
  }
}

.material-icons.md-24 {
  font-size: 24px !important;
}

.material-icons.md-36 {
  font-size: 36px !important;
}

.material-icons.md-48 {
  font-size: 48px !important;
}

.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.mat-mdc-card,
.mat-mdc-option {
  font-family: @font-family-open-sans;
}

.mat-mdc-chip {
  transform             : perspective(1px) !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.svg-18px {
  width : 18px;
  height: 18px;
}

.svg-24px {
  width : 24px;
  height: 24px;
}

.svg-36px {
  width : 36px;
  height: 36px;
}

.svg-48px {
  width : 48px;
  height: 48px;
}

.text-ellipsis {
  width        : 100%;
  text-overflow: ellipsis;
  white-space  : nowrap;
  overflow     : hidden;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.btn {
  &.btn-material {
    line-height     : 1;
    padding         : 3px 5px;
    background-color: transparent;
  }

  &.btn-borderless {
    border: none;
  }
}

.btn-group.btn-group-spacing {
  .btn:not(:first-child) {
    margin-left: 5px;
  }
}

@media screen and (max-width: 900px) {
  body {
    .btn-link.btn-svg {
      cursor: pointer;
    }
  }
}

/** =============== ANGULAR MATERIAL CSS OVERRIDES =========================== **/

.mat-primary .mat-pseudo-checkbox-checked:not(.mat-pseudo-checkbox-disabled),
.mat-primary .mat-pseudo-checkbox-indeterminate:not(.mat-pseudo-checkbox-disabled),
.mat-accent .mat-pseudo-checkbox-checked:not(.mat-pseudo-checkbox-disabled),
.mat-accent .mat-pseudo-checkbox-indeterminate:not(.mat-pseudo-checkbox-disabled),
.mat-pseudo-checkbox-checked:not(.mat-pseudo-checkbox-disabled),
.mat-pseudo-checkbox-indeterminate:not(.mat-pseudo-checkbox-disabled) {
  background: @primary-color;
}

.mat-ink-bar {
  background-color: @app-black  !important;
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button,
.mat-mdc-list-base .mat-mdc-list-item,
.mat-tab-label, .mat-tab-link,
.mat-dialog-title {
  font-family: @font-family-open-sans;
}

.mat-mdc-list {
  .mat-mdc-list-item:focus,
  .mat-mdc-list-item:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: inherit;
}

.mat-mdc-form-field-appearance-outline {
  .mat-mdc-form-field-infix {
    padding: 0.5em 0 1em 0;
  }
}

.mat-mdc-form-field:not(.mat-mdc-form-field-invalid) {
  .mat-mdc-form-field-outline {
    color: #dcdcdc;
  }

  &:not(.mat-mdc-form-field-disabled) {
    .mat-mdc-form-field-flex:hover .mat-mdc-form-field-outline-thick {
      color: #c7c7c7;
    }

    &.mat-focused .mat-mdc-form-field-flex .mat-mdc-form-field-outline-thick {
      color: #c3c3c3;
    }
  }
}

.mat-mdc-form-field.mat-focused {
  .mat-mdc-form-field-label {
    color: @app-black;
  }
}

.mat-mdc-list-base .mat-mdc-list-item {
  font-weight: normal;
  font-size  : @font-size-base;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-line:nth-child(n + 2) {
  font-size: @font-size-base;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}

.phx-mat-dropdown-container {
  position: relative;
  flex    : 1;

  .dropdown-open-overlay {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
    z-index : 1100;
  }

  .dropdown-options-container {
    background-color: white;
    position        : absolute;
    top             : 0;
    left            : 0;
    z-index         : 1200;
    width           : 100%;
    animation       : scale-up-top 0.15s cubic-bezier(0.39, 0.575, 0.565, 1) both;

   .mat-mdc-form-field {
      border-bottom: 1px solid #ececec;
      font-size    : 14px;
    }

   .mat-mdc-selection-list {
      padding-top: 0;
    }

    .mat-mdc-list-base {

      .mat-mdc-list-option,
      .mat-mdc-list-item {
        font-size  : 14px;
        font-family: @font-family-open-sans;
        height     : 40px;
      }
    }

    .mat-mdc-form-field-appearance-outline {
      .mat-mdc-form-field-wrapper {
        margin: 0;
      }

      .mat-mdc-form-field-outline,
      .mat-mdc-form-field-outline-thick {
        display: none;
      }

      .mat-mdc-form-field-outline-start,
      .mat-mdc-form-field-outline-end {
        border-radius: 0;
      }
    }
  }
}

.mat-mdc-snack-bar-container {
  min-width: auto !important;

  &.success {
    --mdc-snackbar-container-color: #4cbb4c;
  }

  &.error {
    --mdc-snackbar-container-color: #d13031;
  }

  &.info {
    --mdc-snackbar-container-color: #5aaafa;
  }

  &.warning {
    --mdc-snackbar-container-color: #fe9701;
  }
}

/** ================ ANGULAR MATERIAL CSS OVERRIDE ENDS ======================= **/

/* ----------------------------------------------
 * Generated by Animista on 2020-4-30 14:43:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-top {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }

  100% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }
}

@keyframes scale-up-top {
  0% {
    -webkit-transform       : scale(0.8);
    transform               : scale(0.8);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }

  100% {
    -webkit-transform       : scale(1);
    transform               : scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin        : 50% 0;
  }
}

.loading-placeholder-animate {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: phx-placeholder-loading 1.4s ease infinite;
  -webkit-animation: phx-placeholder-loading 1.4s ease infinite;
  background-size: 400% 100%;
}

@keyframes phx-placeholder-loading {
  0% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0 50%
  }
}

/* Break overflowing text to wrap onto next line. Class present in bootstrap 4 */
.text-break {
  overflow-wrap: break-word !important;
  word-break   : break-word !important;
}

.vms-timesheet-tooltip {
  text-align : left;
  width      : 200px;
  white-space: normal;
}

.vms-import.profile-basic-data {
  display: flex;

  .profile-img-data {
    flex-grow   : 0;
    flex-shrink : 0;
    padding-left: 0;

    .profile-picture-container {
      margin-right: 5px;
      height      : 42px;
      width       : 45px;
    }
  }

  .profile-r-data {
    flex-grow   : 1;
    flex-shrink : 1;
    padding-left: 8px;

    .profile-path-data {
      margin-bottom: 0;
      font-size    : 12px;
      color        : #506080;

      div>a {
        color: #1e64e1;
      }
    }

    .profile-name {
      padding    : 0;
      font-size  : 24px;
      line-height: 38px;
      margin-top : -5px;
      color      : #1e2430;
      font-weight: 600;
    }
  }
}

.vms-nav-tabs {
  margin-top: 20px;
  border    : 1px solid #e4e4e4;

  .nav-tab-link {
    cursor             : pointer;
    padding            : 20px 5px 20px 10px;
    border-bottom      : 1px solid #e4e4e4;
    transform          : perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: transform;

    .info-tooltip {
      vertical-align: top;
      margin-left   : 4px;
    }

    .tab-identity {
      margin-right: 8px;
    }

    &.active {
      border-left     : 4px solid #686f70;
      background-color: #eaeaea;
      font-weight     : bold;
    }

    &:hover {
      transform: scale(1.03);
    }

    &:hover:not(.active) {
      background-color: #f6f6f6;
    }
  }
}

.vms-custom-grid {
  .dx-datagrid-content {
    .grouping-header-field {
      margin-right: 14px;
    }

    .issues-found {
      color: #ff0000;

      i {
        padding-right : 10px;
        font-size     : 16px;
        vertical-align: middle;
      }
    }

    .no-issues {
      color: #0f9524;

      i {
        padding-right : 10px;
        font-size     : 16px;
        vertical-align: middle;
      }
    }

    .dx-group-row {
      cursor: pointer;
    }

    .dx-datagrid-group-closed,
    .dx-datagrid-group-opened {
      padding-top: 5px;
    }
  }
}

.vms-timesheet-duplicates {
  .mat-mdc-tab-header-pagination-before.mat-mdc-tab-header-pagination-disabled {
    border-right: 1px solid #9aa4b9;
  }

  .mat-mdc-tab-nav-bar {
    border: unset !important;
  }

  .mat-mdc-tab-links {
    >div:last-child {
      border-right: 1px solid #9aa4b9;
    }

    >div+div {
      border-left: 1px solid #9aa4b9;
    }
  }

  .mat-mdc-tab-link {
    padding        : 0 !important;
    height         : 200px !important;
    opacity        : unset !important;
    text-decoration: unset !important;
  }
}

.work-order-mapping-grid {
  .dx-datagrid-rowsview {
    .dx-master-detail-row:not(.dx-datagrid-edit-form) {
      .dx-master-detail-cell {
        background-color: #ffffff;
      }
    }
  }
}

.white-space-pre {
  white-space: pre;
}

.org-state-actions {

  .btn-toolbar,
  .btn-group-xs {
    min-height: 55px;
  }
}
.p-bar {

  .dx-popup-content,
  .dx-popover-arrow::after {
    color           : white;
    font-size       : 16px !important;
    background-color: #354051 !important;
  }

  .dx-popup-content {
    padding: 7px 21px !important;
  }
}

.ck-accord {
  .panel-group {
    margin: 0;
    .panel {
      border    : 0;
      box-shadow: none;

      .panel-heading {
        padding         : 0;
        background-color: white;
      }

      .panel-body {
        padding   : 0;
        border-top: 0 !important;
      }
    }
  }
}

// end deep styles
