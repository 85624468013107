@import './variables.less';

#feedbackType {
    .feedbackHeader {
        font-size: 14px;
        cursor   : pointer;
        padding  : 4px 7px;
    }
}

.btn {
    &.btn-narrow {
        padding: 3px 12px;

        &.btn-gap {
            margin-left: 5px;
        }
    }
}


.pdfContainerReportView {
    height  : calc(~"100vh - 220px");
    position: relative;
}


.file-upload .file-upload-modal-frontdrop .body #noteAreaDiv {
    margin-top: 10em !important;
}


#invoiceReport {
    height     : 100%;
    width      : calc(~"100% - 5px");
    position   : absolute;
    margin-left: 10px;
    border     : none;
}

.manualRateUnit {
    position: relative;
    bottom  : 6px;
    left    : 15px;
}

.manualRateUnit.input-group {
    float        : left;
    padding-right: 10px;
}

.paymentRelease {
    padding-left: 20px;

    .companyHeader {
        cursor      : pointer;
        font-size   : 18px;
        padding     : 0 0 10px 20px;
        border      : 1px solid #d5d5d5;
        border-left : 8px solid #a5a5a5;
        background  : #f5f5f5;
        margin-right: -4px;

        :hover {
            background: #e5e5e5;
        }
    }

    .companyHeaderName {
        padding-top: 8px;

        i {
            position: relative;
            right   : 30px;
            top     : 7px;
        }
    }

    .companyHeaderLine {
        padding: 5px 0;

        hr {
            background-color: #d5d5d5;
            height          : 1px;
            border          : 0;
        }
    }

    .currencyHeader {
        border-bottom: 1px dashed #d5d5d5;
        font-size    : 16px;
        cursor       : pointer;
        padding      : 10px 0 10px 15px;
        margin-top   : 1px;
        margin-right : 5px;

        :hover {
            background: #e5e5e5;
        }

        .currencyBadge {
            position    : relative;
            bottom      : 1px;
            margin-right: 7px;
        }

        .currencyName {
            margin-left: 10px;
        }

        i {
            margin-left: 10px;
        }

        &.currencyHeaderGarnishee {
            padding-bottom: 35px;
            margin-right  : 11px;
        }
    }

    .companyBlock {
        padding-left: 30px;
    }

    .methodBlock {
        margin       : 1px 10px;
        font-size    : 14px;
        padding      : 10px 0 10px 25px;
        border-bottom: 1px solid #d5d5d5;
        text-align   : left;
    }

    .emptyCurrency {
        padding   : 10px 0 10px 25px;
        font-size : 13px;
        color     : red;
        font-style: italic;
    }

    .entityBlock {
        font-size : 14px;
        text-align: left;

        .divRelease {
            padding    : 10px 30px 10px 20px;
            margin     : 3px 40px;
            cursor     : pointer;
            font-weight: 600;

            &.navWithOutLink {
                pointer-events: none;
                color         : #777;
            }
        }

        .divRelease:hover {
            background: #e5e5e5;
        }

        .releaseBadge {
            position  : relative;
            right     : -7px;
            margin-top: -2px;
        }

        .releaseIcon {
            position: relative;
            right   : 25px;
            top     : 1px;
        }
    }
}

.paymentRelease:first-child {
    margin-top: 0;

    hr {
        display: none;
    }

    .companyHeaderLine {
        padding: 0;
    }
}

.btnDirectDeposit {
    position    : relative;
    float       : right;
    margin-right: 10px;
}

.payment-search {
    width: 98% !important;

    .payment-search-div-header {
        padding   : 5px 0 5px 5px;
        margin-top: -8px;
        position  : relative;
        left      : 10px;
    }

    .payment-search-div-body {
        padding-top: 10px;

        .payment-search-cell {
            padding-left: 20px;
        }
    }
}

.payment-search-buttons {

    .payment-search-expand {
        padding-bottom: 2px !important;
        margin-left   : 10px !important;
    }

    .icon-payment-expand {
        position: relative;
        bottom  : 2px;
    }
}

.worker-panel-header {
    margin-top: 10px;

    .panel-title {
        padding-left: 15px !important;
    }
}

.user-profile-table,
.duplicate-commission-rate-table,
.duplicate-workorder-table {
    padding-left: 20px;
    margin      : 0 -10px 10px 0;
}

.user-profile-table-header,
.duplicate-commission-rate-table-header,
.duplicate-workorder-table-header {
    margin-bottom: 5px;
}

.duplicate-workorder-table-row {
    &:hover {
        background-color: #f5f5f5;
    }
}

.user-profile-cell,
.duplicate-commission-rate-table-cell,
.duplicate-workorder-table-cell {
    padding      : 5px 0;
    overflow-wrap: break-word;
}

.user-profile-create-button {
    margin: 0 5px 10px 0;
}

a.user-profile-action,
a.duplicate-commission-rate-table-action,
a.duplicate-workorder-table-action {
    cursor: pointer;
}

.perm-placement-linked-entities-warning,
.user-profile-warning,
.duplicate-commission-warning,
.duplicate-workorder-warning {
    background-color: #fcf8e3;
    border-color    : #fbeed5;
    color           : #c09853;
    padding-top     : 10px;
    padding-left    : 15px;
    margin          : 15px 10px 0 20px;
    border          : 1px solid transparent;
    border-radius   : 4px;
    font-weight     : 700;
    font-size       : 14px;
}

.user-profile-footer,
.duplicate-workorder-footer {
    margin: 0 0 25px 15px;
}

.ts-brief-header-td {
    padding-bottom: 10px !important;
    font-size     : 13px;
    font-weight   : 600;

    .ts-brief-header-sp {
        color    : #959595;
        font-size: 13px;
    }
}

.trn-manual-rate {
    padding-bottom: 5px;

    .trn-manual-rate-drp {
        padding-left: 10px;
    }
}

.journal-pending {
    padding-top: 10px;

    .journalHeader {
        cursor     : pointer;
        font-size  : 18px;
        padding    : 0 0 10px 20px;
        border     : 1px solid #d5d5d5;
        border-left: 8px solid #a5a5a5;
        background : #f5f5f5;
        margin     : 0 -4px 10px 15px;

        &.journalNavBlock {
            pointer-events: none;
        }
    }

    .badge {
        margin-left: 10px;
        position   : relative;
        bottom     : 2px;
    }

    .journalCompanyName {
        padding-top: 12px;

        .journal-chevron {
            position: relative;
            right   : 30px;
            top     : 7px;
        }

        .journalNavBlock {
            pointer-events: none;
        }
    }

    .journal-pending-sup {
        padding: 10px 0 15px 0;

        .journal-button {
            position: relative;
            bottom  : 2px;
        }
    }
}

.journal-search {
    padding-top: 10px;

    .journalHeader {
        cursor      : pointer;
        font-size   : 18px;
        padding     : 0 0 10px 20px;
        border      : 1px solid #d5d5d5;
        border-left : 8px solid #a5a5a5;
        background  : #f5f5f5;
        margin-right: -4px;

        &.journalNavBlock {
            pointer-events: none;
        }
    }

    .badge {
        margin-left: 10px;
        position   : relative;
        bottom     : 2px;
    }

    .journalCompanyName {
        padding-top: 12px;

        .journal-chevron {
            position: relative;
            right   : 30px;
            top     : 7px;
        }

        .journalNavBlock {
            pointer-events: none;
        }
    }
}

.journal-button-style {
    margin-left  : 10px !important;
    margin-bottom: 2px !important;
}

.journalBatchRow {
    .journal-csv-button {
        padding    : 7px 12px 4px;
        margin-left: 20px !important;
    }

    .journal-accpcc-button {
        padding    : 6px 12px 5px;
        margin-left: 15px !important;
    }
}

.journal-batch-td {
    text-align: center;
}

span.select2-chosen>span {
    overflow     : hidden;
    text-overflow: ellipsis;
    white-space  : nowrap;
    text-align   : justify;
}

.panel.panel-internal.contact-panel {
    padding-top: 15px;
}

.btn-group.btn-profile-add {
    float   : right;
    position: relative;
    bottom  : 38px;
}


.contact-page-wrapper {
    margin-left: 0;

    .profile-status {
        margin: 0;

        .profile-status-body {
            margin-top: 5px;

            .profile-status-item {
                padding-left: 0;
            }
        }
    }

    .profile-headstone {
        margin-left: -10px;
    }

    .contact-btn-profile-add {
        margin-left: -10px;
    }
}

.panel.panel-internal {
    &.panel-contact {
        margin       : 0;
        margin-bottom: 15px;
    }

    .contact-name {
        padding     : 8px 25px 0 25px;
        border      : 1px solid #dddddd;
    }

    .user-roles {
        padding-left: 30px;
    }
}

.btn-toolbar.btn-toolbar-profile {
    padding: 15px 30px 15px 0;
}

.profile-form {
    .panel-email {
        .contact-email {
            margin      : 20px 45px 0 45px;
            padding-top : 30px;
            padding-left: 70px;

            .email-input {
                padding-right: 70px;
            }
        }

        .btn-profile-invite {
            position: relative;
            padding : 5px 25px;
        }

        .profile-primary-label {
            position: relative;
            top     : 7px;
        }

        .profile-primary-label-main {
            position: relative;
            top     : 7px;
        }
    }

    .panel-organization {
        margin          : 10px 50px 0 50px;
        background-color: #fafafa;
        padding-bottom  : 10px;
    }

    .panel-profile-info {
        margin: 0 20px;

        .profile-info {
            .panel-body {
                padding-bottom: 5px;
            }

            .panel-info-address {
                padding: 20px 0 15px 15px;
            }

            .panel-info-title {
                padding      : 10px 35px;
                margin-bottom: -20px;
            }

            .panel-info-phone {
                padding: 20px 0 15px 15px;

                .profile-phone-main {
                    padding: 0 3px 0 0;
                }

                .profile-phone-ext {
                    padding: 0;

                    .profile-phone-ext-span {
                        font-weight: 600;
                    }

                    .fieldViewMode {
                        display: inline-block;
                    }
                }
            }
        }

        .profile-worker-org-lbl {
            position: relative;
            right   : 10px;
            top     : 5px;

            span {
                float: right;
            }
        }

        .profile-worker-org-url {
            position: relative;
            bottom  : 3px;
        }

        .profile-worker-block-pt {

            .fieldViewMode {
                position: relative;
                bottom  : 8px;
            }
        }

        .profile-worker-wrapper {
            margin-top: -15px;
        }

        .profile-personal-info {
            background: #fafafa;

            .profile-personal-info-title {
                position: relative;
                right   : 26px;
            }
        }

        .personal-info-form-content {
            padding-right : 15px;
            padding-bottom: 5px;

            .profile-payment-chk {
                padding-left: 13px;
            }

            .profile-payment-lbl {
                .lbl-pref {
                    position: relative;
                    left    : 18px;
                    top     : 10px;
                }

                .lbl-payment {
                    position: relative;
                    left    : 0;
                    top     : 10px;
                }
            }

            .profile-payment-txts {
                padding-left: 11px;

                .dd-input {
                    padding-right: 22px;
                }
            }

            .profile-payment-txts:first-child {
                margin-top: 20px;
            }

            .panel-sales-tax {
                padding: 10px 0 15px 0;

                .btn-sales-tax-add {
                    position: relative;
                    top     : -5px;
                }

                .panel-sales-tax-body {
                    padding-top: 15px;
                }

                .btn-sales-tax-delete {
                    margin-right: 15px;
                }
            }

            .panel-profile-radio {
                padding-top: 5px;

                .radio-inline:nth-child(2) {
                    margin-left: 25px;
                }
            }
        }

        .personal-info-birth-sin {
            width: 31.5%;
        }

        .personal-info-birth-date {
            .input-group {
                width: 36.1% !important;
            }

            input {
                width: 100% !important;
            }

            .input-group-addon {
                padding: 0 8px;
            }
        }

        .profile-personal-info-wrapper {
            padding   : 0 20px;
            margin-top: 10px;

            .wt-title {
                margin: 10px 0 15px 0;
            }
        }
    }

    .panel-internal-block {
        margin          : 10px 50px 0 50px;
        background-color: #fafafa;
        padding-bottom  : 10px;

        .compliance-title {
            margin: 15px 0 0 20px;
        }

        .compliance-body {
            margin-top: -15px;
        }

        .compliance-document-entity {
            border: none !important;

            .compliance-document {
                margin-top: 5px;
            }
        }
    }
}


.base-contact-form {
    .profile-collapse-wrapper {
        margin: 0;

        .profile-collapse {
            background-color: #fafafa;
            border          : 1px solid #dddddd;
            padding         : 10px 0;
            margin-left     : 0;

            .profile-title {
                font-size  : 18px;
                font-weight: 500;
                margin-left: 10px;
            }

            .contact-block-expand-btn {
                padding-bottom  : 2px !important;
                margin-left     : 10px !important;
                position        : relative;
                right           : 15px;
                background-color: #ffffff;

                .contact-block-expand-icon {
                    position: relative;
                    bottom  : 2px;
                }
            }
        }

        &.advance-garnishee-wrapper {
            margin-top: 15px;

            .count-badge {
                position: relative;
                bottom  : 2px;
                left    : 3px;
                padding : 2px 5px;
            }

            .badge-adv {
                left: 18px;
            }
        }
    }

    .contact-source-nav {
        position : relative;
        bottom   : 31px;
        right    : 20px;
        font-size: 14px;
    }

    .fieldViewMode {
        font-size: 13px;
    }

    .worker-profile-docs-header {
        padding      : 15px 0;
        margin-bottom: 0;
    }

    .worker-profile-docs-wrapper {
        margin-bottom: 0;
        padding-left : 40px;
    }

    .worker-profile-docs-list:first-child {
        margin-top: 18px;
    }

    .worker-profile-docs-list {
        margin-bottom: 3px;

        .worker-link-text {
            display      : inline-block;
            width        : 300px;
            white-space  : nowrap;
            overflow     : hidden;
            text-overflow: ellipsis;
            position     : relative;
            top          : 5px;
            font-size    : 14px;
            margin-left  : 10px;
        }

        .worker-link-view {
            font-size      : 15px;
            text-decoration: none;
        }

        .worker-link-delete {
            font-size      : 18px;
            position       : relative;
            top            : 2px;
            text-decoration: none;
            margin-left    : 15px;
        }
    }
}


.panel-organization-address,
.panel-organization-client-alternate-bills,
.panel-internal-bank-accounts {
    border-bottom : 1px solid #c9c9c9 !important;
    padding-bottom: 10px;

    &:not(:nth-child(2)) {
        padding-top: 20px;
    }
}

.panel-organization-tax-number,
.panel-organization-client-alternate-bills {
    border-bottom : 1px solid #c9c9c9 !important;
    padding-bottom: 5px;

    &:not(:first-child) {
        padding-top: 20px;
    }
}


.sales-tax-details {
    padding-bottom: 30px;

    .sales-tax-entity-wrapper {
        border     : 1px solid #dddddd;
        padding-top: 20px;
    }

    .sales-tax-actions-wrapper {
        .sales-tax-actions-drp {
            margin-left: -20px;
        }

        height: 50px;
    }

    .sales-tax-lbl {
        position: relative;
        top     : 7px;
    }

    .sales-tax-effective-date {
        .input-group {
            width: 100% !important;
        }

        input {
            width: 100% !important;
        }

        .input-group-addon {
            padding: 0 8px;
        }
    }

    .sales-tax-header-row {
        margin-top    : 10px;
        padding-bottom: 10px;
    }

    .sales-tax-provinces {
        margin: 20px 0;

        .sales-tax-provinces-header {
            padding   : 5px 0 5px 5px;
            margin-top: -8px;
            position  : relative;
            left      : 10px;

            .sales-tax-provinces-header-last {
                margin-left: 10px;
            }
        }

        .sales-tax-provinces-body {
            padding      : 5px 0;
            border-bottom: 1px solid #dddddd;
        }

        .sales-tax-province-cell {
            padding: 7px 0 0 12px;
        }

        .sales-tax-radio-cell {
            padding-top: 5px;

            .radio-inline:first-child {
                margin-left: 5px;
            }

            .radio-inline:nth-child(2) {
                margin-left: 40px;
            }

            .fieldViewMode {
                margin-left: 30px;
                margin-top : -5px;
            }
        }

        .sales-tax-percent-amount {
            height: 30px;
        }
    }

    .sales-tax-percent-cell {
        .fieldViewMode {
            margin-left: 50px;
        }
    }

    .fieldViewMode {
        font-size: 14px;
        position : relative;
        top      : -2px;
    }
}

.advance-search-table {
    margin-top: 20px;

    .advance-collapsible-body {
        min-height: 50px;
        height    : auto !important;
        max-height: 250px !important;
    }
}

.advance-new {

    .btn-advance-drp {
        margin-left: 0 !important;
    }

    .advance-detail-input {
        position   : relative;
        top        : 7px;
        word-break : break-word;
    }

    .advance-detail-text {
        margin-top: 12px;
    }

    .advance-info {
        background-color: #fafafa;
        height          : 100%;

        .advance-details {
            .input-group-addon {
                padding: 0 15px;
            }

            .advance-new-header {
                padding: 40px 0 20px 50px;
            }

            .advance-view-header {
                padding: 30px 0 0 50px;
            }

            .advance-view-body {
                margin-bottom: 0;
            }

            .advance-new-body {
                margin-bottom: 40px;

                .advance-issue-date {
                    .input-group {
                        width: 100% !important;
                    }

                    input {
                        width: 100% !important;
                    }

                    .input-group-addon {
                        padding: 0 8px;
                    }
                }
            }
        }

        .advance-transaction-details {
            .advance-view-header {
                padding-left: 50px;
            }

            .advance-view-body {
                margin-bottom   : 0;
                background-color: #fafafa;
                min-height      : 220px;
            }

            .advance-trn {
                padding: 0 10px 0 30px;

                .advance-trn-no-repayments {
                    font-style: italic;
                    font-size : 15px;
                    color     : #D35400 !important;
                }
            }

            .advance-trn-header {
                padding   : 5px 0 5px 5px;
                margin-top: -8px;
            }

            .advance-trn-body {
                padding-top: 10px;

                .advance-trn-cell {
                    padding-left: 20px;
                }

                .advance-trn-cell-ext {
                    padding-left: 35px;
                }
            }
        }

        .advance-payee-details {
            .panel-title {
                padding-left: 40px;
            }

            .panel-body {
                padding-top: 30px;
            }
        }

        .advance-payee-view-details {
            .panel-title {
                padding-left: 40px;
            }

            .panel-body {
                padding-top: 10px;
            }
        }

        .advance-payment-details {
            .panel-body {
                padding-top: 30px;
            }
        }

        .advance-payment-view-details {
            .panel-body {
                padding-top: 10px;
            }
        }

        .advance-new-radio {
            padding: 1px 0 5px 0;
        }

        .advance-payback-percentage {
            padding: 0 13px !important;
        }
    }

    .advance-new-footer {
        margin-top   : 30px;
        margin-left  : -10px;
        padding-right: 40px;

        .btn {
            margin-left: 10px;
        }
    }

    .advance-detail-footer {
        padding-right: 40px;
        margin-left  : -10px;

        .btn {
            margin-left: 10px;
        }
    }

    .advance-footer-wrapper {
        padding-bottom: 20px;
    }
}

.garnishee-search-table {
    margin-top: 20px;

    .garnishee-collapsible-body {
        min-height: 50px;
        height    : auto !important;
        max-height: 250px !important;
    }
}

.btn-garnishee-search-nav {
    width     : 100%;
    margin-top: 10px;

    .garnishee-btn-wrapper {
        .dropdown-toggle {
            width: 200px;
        }

        .garnishee-btn-caret {
            float   : right;
            position: relative;
            top     : 8px;
            right   : 5px;
        }

        @-moz-document url-prefix() {
            .garnishee-btn-caret {
                float   : right;
                position: relative;
                top     : -9px;
                right   : 5px;
            }
        }

        @-moz-document url-prefix() {
            .garnishee-btn-name {
                position: relative;
                top     : 2px;
            }
        }
    }
}





.garnishee-footer-div {
    .btn {
        margin-left: 10px;
    }
}

.garnishee-modal {
    .modal-body {
        max-height: 100%;
    }
}

.top9 {
    top: 9px;
}

.rebate-fee {
    padding-left: 30px;

    .rebate-fee-header-row {
        margin-top    : 10px;
        padding-bottom: 10px;
    }

    .btn-rebate-fee-search-nav {
        width : 100%;
        margin: 10px 0 40px 0;

        .rebate-fee-btn-wrapper {
            .dropdown-toggle {
                width: 75px;
            }

            .rebate-fee-btn-caret {
                float   : right;
                position: relative;
                top     : 9px;
                right   : 2px;
            }

            @-moz-document url-prefix() {
                .rebate-fee-btn-caret {
                    float   : right;
                    position: relative;
                    top     : -9px;
                    left    : 2px;
                }
            }

            @-moz-document url-prefix() {
                .rebate-fee-btn-name {
                    position: relative;
                    top     : 2px;
                }
            }
        }
    }

    .rebate-fee-actions-drp {
        position: relative;
        bottom  : 60px;

        .btn-rebate-fee-search-nav {
            margin: 10px 0 0 0;

            .dropdown-toggle {
                width: 75px;
            }
        }
    }

    .vms-fee-rebate-header {
        margin-top: 20px;
    }
}

.sales-pattern-dlg-errors {
    .modal-content {
        width: 600px;

        .modal-header {
            button {
                position: relative;
                top     : 5px;
            }

            .fa.fa-info {
                margin-right: 5px;
            }
        }

        .modal-body {
            min-height   : 200px;
            padding-top  : 30px;
            padding-left : 40px;
            color        : red;
            font-size    : 14px;
            margin-bottom: 10px;
        }
    }
}

.vms-fee-details {
    padding-bottom: 30px;

    .btn-vms-fee-drp {
        margin-left: 0 !important;
    }

    .input-group-addon {
        padding: 8px 13px;

        &.vms-fee-addon {
            padding: 8px 15px;
        }
    }

    .vms-fee-actions-wrapper {
        .vms-fee-actions-drp {
            margin-left: -20px;
        }

        height: 50px;
    }

    .vms-fee-lbl {
        position: relative;
    }

    .vms-fee-effective-date {
        .input-group {
            width: 100% !important;
        }

        input {
            width: 100% !important;
        }

        .input-group-addon {
            padding: 0 8px;
        }
    }

    .vms-fee-header-row {
        margin-top    : 10px;
        padding-bottom: 10px;
    }

    .fieldViewMode {
        font-size: 14px;
        position : relative;
        left     : 5px;
        bottom   : 1px;
    }

    @-moz-document url-prefix() {
        .fieldViewMode {
            font-size: 14px;
            position : relative;
            left     : 5px;
            bottom   : 4px;
        }
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .fieldViewMode {
            font-size: 14px;
            position : relative;
            left     : 5px;
        }
    }
}

.vms-fee-versions {
    margin-bottom: 20px;
    padding-left : 0;

    .vms-fee-version-group {
        position        : relative;
        display         : block;
        padding         : 5px;
        background-color: #ffffff;
        margin-bottom   : 10px;

        .version-item {
            border-bottom : 2px solid @accent-color;
            padding-bottom: 10px;

            .version-id {
                font-size: 16px;
            }
        }

        div.version-version-item {
            cursor : pointer;
            padding: 5px;
            margin : 5px 0;

            .small-text {
                color    : #333333;
                font-size: 11px;
            }

            &.active {
                background-color: #ebebeb;
            }
        }
    }

    .vms-fee-version-group.active,
    .vms-fee-version-group.active:hover,
    .vms-fee-version-group.active:focus {
        z-index         : 2;
        background-color: #fafafa;
    }
}

.commission-adjustment-edit {
    margin-top: 20px;

    .adjustment-body {
        .adjustment-edit-header {
            margin-bottom: 20px;
        }

        .control-label {
            margin-top: 6px;
        }

        .job-owner-details {
            .control-label {
                margin-top: 0;
            }
        }

        .adj-lbl-text {
            position: relative;
            bottom  : 6px;
        }

        .adj-lbl-ext {
            position: relative;
            left    : 5px;
        }

        .btn-adj-drp {
            margin-left: 0 !important;
        }

        .btn-adj-add {
            padding: 7px 10px 2px 10px;
        }

        .adj-commission-company {
            position: relative;
            top     : 6px;
        }

        .adj-doc-btn {
            padding : 4px 10px;
            position: relative;
            top     : 5px;
        }

        .commission-link-text {
            display      : inline-block;
            width        : 300px;
            white-space  : nowrap;
            overflow     : hidden;
            text-overflow: ellipsis;
            position     : relative;
            font-size    : 14px;
        }

        .commission-link-view {
            font-size      : 15px;
            text-decoration: none;
        }

        .source-files-container {
            margin-top: 9px;
        }

        .adjustment-commission-date {
            .input-group {
                width: 100% !important;
            }

            input {
                width: 100% !important;
            }

            .input-group-addon {
                padding: 0 8px;
            }
        }

        .adjustment-radio {
            padding: 5px 0;

            .radio-inline:first-child {
                margin-left: 5px;
            }

            .radio-inline:nth-child(2) {
                margin-left: 30px;
            }

            .fieldViewMode {
                margin-left: 4px;
            }

            &.adjustment-amount-type .radio-inline {
                margin-top: 6px;
            }
        }

        .fieldViewMode {
            font-size: 14px;
            position : relative;
            bottom   : 3px;
        }

        .view-mode-link {
            position: relative;
            top     : 3px;
        }

        .adj-input-amount-net {
            .fieldViewMode {
                display : inline-flex;
                position: relative;
                bottom  : 2px;
            }

            .adj-amont-net-lbl {
                position : relative;
                bottom   : 1px;
                font-size: 14px;
            }
        }

        .adjustment-edit-background-check {
            .adjustment-edit-workorder {
                margin-top: 25px;
            }

            .adjustment-edit-jobowner {
                margin-top: 25px;
            }

            .alloc-header {
                font-size: 140%;
            }

            .btn-adj-alloc-add {
                padding : 5px 5px 0 5px;
                border  : 1px solid #a5a5a5;
                position: relative;
                bottom  : 2px;
            }
            .lbl-jo {
                position: relative;
                bottom  : 7px;
            }

            .adj-amount-h {
                position   : relative;
                bottom     : 8px;
                margin-left: 10px;
            }

            .btn-adj-del {
                position: relative;
                right   : 10px;
            }

            .adj-commission-type {
                margin-top   : 15px;
                margin-bottom: -5px;
            }

            .adj-wo-lbl {
                display      : inline-block;
                white-space  : nowrap;
                overflow     : hidden;
                text-overflow: ellipsis;
            }

            .adj-input-amount {
                .fieldViewMode {
                    display : inline-flex;
                    position: relative;
                    bottom  : 1px;
                }

                .adj-amont-net-lbl {
                    position : relative;
                    bottom   : 0;
                    font-size: 14px;
                }
            }

            .adj-no-alloc {
                font-style: italic;
                font-size : 15px;
                color     : red;
            }
        }
    }

    .adjustment-footer {
        border: 1px solid #dddddd;
    }
}

.align-table-cells-center {

    th,
    td {
        text-align: center;
    }
}

/* The switch - the box around the slider */
.switch {
    position    : relative;
    display     : inline-block;
    width       : 45px;
    height      : 19px;
    padding-left: 5px;
    margin-left : 3px;
}

/* The slider */
.slider {
    position          : absolute;
    cursor            : pointer;
    top               : 0;
    left              : 0;
    right             : 0;
    bottom            : 0;
    background-color  : #ccc;
    -webkit-transition: .4s;
    transition        : .4s;
}

.slider:before {
    position          : absolute;
    content           : "";
    height            : 15.5px;
    width             : 15.5px;
    left              : 2px;
    bottom            : 2px;
    background-color  : white;
    -webkit-transition: .4s;
    transition        : .4s;
}

.slider.slider-smaller:before {
    position          : absolute;
    content           : "";
    height            : 13.5px;
    width             : 13.5px;
    left              : 2px;
    bottom            : 2px;
    background-color  : white;
    -webkit-transition: .4s;
    transition        : .4s;
}

input:checked+.slider {
    background-color: #4682B4;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform    : translateX(26px);
    transform        : translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.select-all-slider-div {
    display      : inline-flex;
    border-bottom: 1px solid cornflowerblue;
}

.file-upload {
    .filename {
        display: inline-block;
    }
}

.btn-group {
    .btn-row-action.no-padding-bottom-right {
        padding-right : 0;
        padding-bottom: 0;
    }

    .btn-row-action.no-padding-bottom {
        padding-bottom: 0;
    }
}

.po-tab-details {
    .po-lines-lbl {
        position: relative;
        bottom  : 12px;
    }
}

.wo-tab-po {
    .po-tr {
        cursor: pointer;
    }

    .po-edit-td {
        min-width: 150px;

        .btn-po-edit {
            padding     : 4px;
            margin-right: 4px;
        }
    }

    .po-td-text {
        position: relative;
        top     : 12px;
    }
}


.job-owner-use-support {
    margin-top: 7px;

    .fieldViewMode {
        font-size    : 13px;
    }
}

table.app-vms-totals {
    width : 100%;
    margin: 20px;

    td {
        padding: 5px 10px;
    }
}

.record-count {
    color      : #333333;
    padding    : 20px 0;
    margin-left: 5px;

    .record-count-value {
        font-weight: bold;
    }
}

.app-accordion-container {

    &.app-accordion-container-28 {
        margin: 0 7px 0 28px;
    }

    .app-accordion-chevron {
        position: relative;
        top     : 5px;
        float   : right !important;
    }

    .app-badge {
        position: relative;
        bottom  : 1px;
    }

    .app-no-link {
        color: #777;
    }

    p.app-no-items {
        text-align: center;
        font-size : 16px;
    }

    .app-accordion-level-one {
        .app-accordion-level-one-item {
            border     : 1px solid #d5d5d5;
            border-left: 8px solid #a5a5a5;
            background : #f5f5f5;
            font-size  : 18px;
            cursor     : pointer;
            margin     : 2px 0;
            padding    : 10px 15px;

            &:hover {
                background: #e5e5e5;
            }
        }
    }

    .app-accordion-level-two {
        padding: 0 40px;

        .app-accordion-level-two-item {
            font-size: 14px;
            cursor   : pointer;
            margin   : 2px 0;
            padding  : 10px 15px;

            &:hover {
                background: #e5e5e5;
            }
        }
    }

    .app-accordion-level-three {
        padding: 0 40px;

        .app-accordion-column {
            margin   : 0 10px 10px 10px;
            font-size: 16px;
        }

        .app-accordion-level-three-item {
            font-size  : 14px;
            font-weight: bolder;
            cursor     : pointer;
            margin     : 2px 0;
            padding    : 5px 10px;

            &:hover {
                background: #e5e5e5;
            }

            &.app-no-link {
                pointer-events: none;
                color         : #777;
            }
        }
    }

    @media only screen and (max-width : 1200px) {
        &.app-accordion-container-28 {
            margin: 0 20px 0 28px;
        }
    }

    @media only screen and (max-width: 767px) {
        &.app-accordion-container-28 {
            margin: 0 8px 0 0;
        }

        .app-accordion-level-two {
            padding: 0;
        }

        .app-text-right-sm {
            text-align: right;
        }
    }
}

#SuppressPayment {
    label {
        margin: 0;
    }
}

.billing-info-apply {
    .form-control-static {
        padding   : 1px 0 0 0;
        margin    : 0;
        min-height: 0;
    }
}

.panel-heading.test-headstone {
    background: @brand-test;
}
