// Core variables and mixins
@import "variables.less";
@import "mixins.less";
//
// Input groups
// --------------------------------------------------
.input-group-addon,
.select2-container,
.select2-choice,
.select2-container .select2-choices .select2-search-field input,
.select2-container .select2-choice, .select2-container .select2-choices,
.select2-search input {
    border-radius: 0;
}



    // Reset rounded corners
    .input-group .form-control:first-child,
    .input-group-addon:first-child,
    .input-group-btn:first-child > .btn,
    .input-group-btn:first-child > .dropdown-toggle,
    .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
        .border-right-radius(0);
    }

        .input-group-addon:first-child,
        .input-group-btn:first-child > .btn:last-child {
            border-right: 0;
        }

        .input-group .form-control:last-child,
        .input-group-addon:last-child,
        .input-group-btn:last-child > .btn,
        .input-group-btn:last-child > .dropdown-toggle,
        .input-group-btn:first-child > .btn:not(:first-child) {
            .border-left-radius(0);
        }

            .input-group-addon:last-child,
            .input-group-btn:last-child > .btn:first-child {
                border-left: 0;
            }

.input-group {
    .form-control.ui-select-container {
        z-index: auto; //fix to allow select2 styled drop-downs with Bootstrap 3.1.1+ bootstrap
    }

        .form-control.ui-select-container.ng-empty + .input-group-btn {
            display: none;
        }

    .select2-container { // https://github.com/fk/select2-bootstrap-css/commit/03302f11c67b7cb65a5902b7a7bb2f1eabdcd6df
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0;
        display: table; /* 1 */
        table-layout: fixed; /* 1 */
    }

        .select2-container + .input-group-btn {
            right: 50px;
            position: absolute !important;

            button.btn {
                top: 2px;
                border: none;
                background: none;
            }
        }
}
