// Core variables and mixins
@import "variables.less";
@import "mixins.less";

//
// Grid system
// --------------------------------------------------

//some page-content variables
@content-bg: var(--fbo-theme-content-bg, #FFF);
@content-header-border: #E2E2E2;
@content-header-color: #2679B5;
@content-header-size: 24px;
@content-header-small-color: #8089A0;
@content-header-small-size: 14px;

html {
    min-height: 100%;
    position: relative;
}

body {
    padding-bottom: 0;
    background-color: @body-bg;
    min-height: 100%;
    font-family: @font-family-open-sans;
    font-size: 13px;
    color: @text-color;
    line-height: 1.5;
    overflow-x: hidden;

    &.navbar-fixed {
        padding-top: @navbar-mh;
    }

    &.breadcrumbs-fixed {
        padding-top: @navbar-mh + @breadcrumb-height;
    }
}

.fieldViewMode {
    font-size: 12px;
    word-wrap: break-word;
	pre {
		background-color: inherit;
		border: none;
        white-space: pre-wrap;
	}
}

app-home .fieldViewMode {
    font-size: 18px;
}

/* ace default theme layout sections */
.main-container {
    position: static;
    padding: 0;

    > .main-container-inner {
        position: relative;
    }
}


@media (min-width: @screen-tablet) {
    .container.main-container:after {
        .box-shadow(~"0 0 0 1px rgba(0,0,0,0.1)");
    }
}
 

.main-content {
	margin-left: @sidebar-min-width;
	margin-right: 0;
	margin-top: 0;
	min-height: 100%;
	padding: 0;

	&.home\.mcomponent, &.unavailablecomponent {
		margin: 0;
		.page-content {
			padding: 0;
			margin: 0;
			max-width: initial!important;
			#wrapper {
				padding: 0;
				width: auto;
			}
		}
	}
    &.side-bar{
        margin-right: 60px;
        &.sidebar-open{
            /*NOTE: open sidebar shouldn't overlap content - so push content over */
            margin-right: 440px;
        }
    }
}

/*NOTE: when medium or smaller screensize - sidebar overlaps page content*/
@media screen and (max-width:@screen-md) {
    .main-content{
        &.side-bar{
            &.sidebar-open{
                margin-right: 60px;
            }
        }
    }
}

@media (min-width: @screen-desktop) {
	.main-content {
		&.home\.mcomponent {
			.page-content {
				#wrapper {
					width: 960px;
					.container {
						width: 960px;
						padding: 0;
					}
				}
			}
		}
	}
}

.page-content {
    background: @content-bg;
    margin: 0;
    padding: 28px 20px 24px;
    margin-left: (@grid-gutter-width / 2);
    margin-right: auto;
}

@media (max-width: @screen-desktop) {
    .page-content {
        margin-left: auto;
    }
}

.page-header {
    margin: 0 0 12px;
    border-bottom: 1px dotted @content-header-border;
    padding-bottom: 16px;
    padding-top: 7px;

    h1 {
        padding: 0;
        margin: 0 8px;
        font-size: @content-header-size;
        font-weight: lighter;
        color: @content-header-color;

        small {
            margin: 0 6px;
            font-size: @content-header-small-size;
            font-weight: normal;
            color: @content-header-small-color;
        }
        //small
    }
    //h1
}
//.page-header


