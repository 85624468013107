// Core variables and mixins
@import "variables.less";
@import "mixins.less";

.badge {
    background-color: #B9B9B9;
    min-width: 10px;
}
// Warnings (orange)
.badge-warning {
	background-color: @accent-color;
}

.badge-warning[href] {
	background-color: #c46500;
}
// Success (green)
.badge-success {
	background-color: @state-success-text;
}

.badge-success[href] {
	background-color: #356635;
}
// Info (turquoise)
.badge-info {
	background-color: @state-info-text;
}

.badge-info[href] {
	background-color: #2d6987;
}

.margin-top-bottom-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}
