@import './../less/variables.less';
// COLORING
// ----------------------------------------------------

.border-white { border-color: white; }


// BACKGROUND COLOR
// ---------------------------------------------------- 
.bg-white       		{ background-color: #fff !important; }
.bg-white25				{ background-color: rgba(255, 255, 255, .25); }
.bg-white50				{ background-color: rgba(255, 255, 255, .50); }
.bg-white75				{ background-color: rgba(255, 255, 255, .75); }
	
.bg-gray-light 			{ background-color: #EDEFF2 !important; }
.bg-gray-light2			{ background-color: #FAFAFA !important; }
.bg-gray-medium 		{ background-color: #e2e4e8 !important; }
.bg-gray-strong 		{ background-color: #dadce1 !important; }
	
.bg-yellow-light 		{ background-color: #FEFBEA !important; }
.bg-yellow-medium 		{ background-color: #FDF7D5 !important; }
.bg-yellow-strong 		{ background-color: #FDF3BF !important; }
	
.bg-blue-light 			{ background-color: #E9F3FD !important; }
.bg-blue-medium 		{ background-color: #D4E6F9 !important; }
.bg-blue-strong 		{ background-color: #BFD9F4 !important; }
	
.bg-green-light 		{ background-color: #E6F6E5 !important; }
.bg-green-medium 		{ background-color: #D1EED0 !important; }
.bg-green-strong 		{ background-color: #BCE5BB !important; }

// HR coloring
.bg-gray-light > hr 	{ border-top-color: #d9dadc; }
.bg-gray-medium > hr 	{ border-top-color: #cbced2; border-bottom-color: #f3f4f4; }
.bg-gray-strong > hr 	{ border-top-color: #c2c3c7; border-bottom-color: #eeefef; }
.bg-yellow-light > hr  	{ border-top-color: #f0eaca; border-bottom-color: #ffffff; }
.bg-yellow-medium > hr 	{ border-top-color: #ede19a; border-bottom-color: #ffffff; }
.bg-yellow-strong > hr 	{ border-top-color: #ecd978; border-bottom-color: #ffffff; }
.bg-blue-light > hr 	{ border-top-color: #ccd9e3; }
.bg-blue-medium > hr 	{ border-top-color: #c2d3e0; border-bottom-color: #f3f8fd; }
.bg-blue-strong > hr 	{ border-top-color: #b1c5d8; border-bottom-color: #eef4fb; }
.bg-green-light > hr	{ border-top-color: #c7ddc4; }
.bg-green-medium > hr	{ border-top-color: #bfd7bc; border-bottom-color: #f2fbee; }
.bg-green-strong > hr	{ border-top-color: #adc9ad; border-bottom-color: #e9f5e4; }


// BASE COLOR
// ---------------------------------------------------- 
.text-color 				{ color:#333333 !important}

.text-aliceblue 			{ color:#f0f8ff !important}
.text-antiquewhite 			{ color:#faebd7 !important}
.text-aqua 					{ color:#00ffff !important}
.text-aquamarine 			{ color:#7fffd4 !important}
.text-azure 				{ color:#f0ffff !important}
.text-beige 				{ color:#f5f5dc !important}
.text-bisque 				{ color:#ffe4c4 !important}
.text-black 				{ color:#000000 !important}
.text-blanchedalmond 		{ color:#ffebcd !important}
.text-blue 					{ color:#0000ff !important}
.text-link-blue     { color: #337ab7 !important}
.text-erblue 				{ color:#34a4dc !important}
.text-blueviolet 			{ color:#8a2be2 !important}
.text-brown 				{ color:#a52a2a !important}
.text-burlywood 			{ color:#deb887 !important}
.text-cadetblue 			{ color:#5f9ea0 !important}
.text-chartreuse 			{ color:#7fff00 !important}
.text-chocolate 			{ color:#d2691e !important}
.text-coral 				{ color:#ff7f50 !important}
.text-cornflowerblue 		{ color:#6495ed !important}
.text-cornsilk 				{ color:#fff8dc !important}
.text-crimson 				{ color:#dc143c !important}
.text-cyan 					{ color:#00ffff !important}
.text-darkblue 				{ color:#00008b !important}
.text-darkcyan 				{ color:#008b8b !important}
.text-darkgoldenrod 		{ color:#b8860b !important}
.text-darkgray 				{ color:#a9a9a9 !important}
.text-darkgreen 			{ color:#006400 !important}
.text-darkgrey 				{ color:#a9a9a9 !important}
.text-darkkhaki 			{ color:#bdb76b !important}
.text-darkmagenta 			{ color:#8b008b !important}
.text-darkolivegreen 		{ color:#556b2f !important}
.text-darkorange 			{ color:#ff8c00 !important}
.text-darkorchid 			{ color:#9932cc !important}
.text-darkred 				{ color:#8b0000 !important}
.text-darksalmon 			{ color:#e9967a !important}
.text-darkseagreen 			{ color:#8fbc8f !important}
.text-darkslateblue 		{ color:#483d8b !important}
.text-darkslategray 		{ color:#2f4f4f !important}
.text-darkslategrey 		{ color:#2f4f4f !important}
.text-darkturquoise 		{ color:#00ced1 !important}
.text-darkviolet 			{ color:#9400d3 !important}
.text-deeppink 				{ color:#ff1493 !important}
.text-deepskyblue 			{ color:#00bfff !important}
.text-dimgray 				{ color:#696969 !important}
.text-dodgerblue 			{ color:#1e90ff !important}
.text-firebrick 			{ color:#b22222 !important}
.text-floralwhite 			{ color:#fffaf0 !important}
.text-forestgreen 			{ color:#228b22 !important}
.text-fuchsia 				{ color:#ff00ff !important}
.text-gainsboro 			{ color:#dcdcdc !important}
.text-ghostwhite 			{ color:#f8f8ff !important}
.text-gold 					{ color:#ffd700 !important}
.text-goldenrod 			{ color:#daa520 !important}
.text-gray 					{ color:#808080 !important}
.text-green 				{ color:#008000 !important}
.text-greenyellow 			{ color:#adff2f !important}
.text-grey 					{ color:#808080 !important}
.text-honeydew 				{ color:#f0fff0 !important}
.text-hotpink 				{ color:#ff69b4 !important}
.text-indianred 			{ color:#cd5c5c !important}
.text-indigo 				{ color:#4b0082 !important}
.text-ivory 				{ color:#fffff0 !important}
.text-khaki 				{ color:#f0e68c !important}
.text-lavender 				{ color:#e6e6fa !important}
.text-lavenderblush 		{ color:#fff0f5 !important}
.text-lawngreen 			{ color:#7cfc00 !important}
.text-lemonchiffon 			{ color:#fffacd !important}
.text-lightblue 			{ color:#add8e6 !important}
.text-lightcoral 			{ color:#f08080 !important}
.text-lightcyan 			{ color:#e0ffff !important}
.text-lightgoldenrodyellow 	{ color:#fafad2 !important}
.text-lightgray 			{ color:#d3d3d3 !important}
.text-lightgreen 			{ color:#90ee90 !important}
.text-lightgrey 			{ color:#d3d3d3 !important}
.text-lightpink 			{ color:#ffb6c1 !important}
.text-lightsalmon 			{ color:#ffa07a !important}
.text-lightseagreen 		{ color:#20b2aa !important}
.text-lightskyblue 			{ color:#87cefa !important}
.text-lightslategray 		{ color:#778899 !important}
.text-lightslategrey 		{ color:#778899 !important}
.text-lightsteelblue 		{ color:#b0c4de !important}
.text-lightyellow 			{ color:#ffffe0 !important}
.text-lime 					{ color:#00ff00 !important}
.text-limegreen 			{ color:#32cd32 !important}
.text-linen 				{ color:#faf0e6 !important}
.text-magenta 				{ color:#ff00ff !important}
.text-maroon 				{ color:#800000 !important}
.text-mediumaquamarine 		{ color:#66cdaa !important}
.text-mediumblue 			{ color:#0000cd !important}
.text-mediumorchid 			{ color:#ba55d3 !important}
.text-mediumpurple 			{ color:#9370d8 !important}
.text-mediumseagreen 		{ color:#3cb371 !important}
.text-mediumslateblue 		{ color:#7b68ee !important}
.text-mediumspringgreen 	{ color:#00fa9a !important}
.text-mediumturquoise 		{ color:#48d1cc !important}
.text-mediumvioletred 		{ color:#c71585 !important}
.text-midnightblue 			{ color:#191970 !important}
.text-mintcream 			{ color:#f5fffa !important}
.text-mistyrose 			{ color:#ffe4e1 !important}
.text-moccasin 				{ color:#ffe4b5 !important}
.text-navajowhite 			{ color:#ffdead !important}
.text-navy 					{ color:#000080 !important}
.text-oldlace 				{ color:#fdf5e6 !important}
.text-olive 				{ color:#808000 !important}
.text-olivedrab 			{ color:#6b8e23 !important}
.text-orange 				{ color:#ffa500 !important}
.text-orangered 			{ color:#ff4500 !important}
.text-orchid 				{ color:#da70d6 !important}
.text-palegoldenrod 		{ color:#eee8aa !important}
.text-palegreen 			{ color:#98fb98 !important}
.text-paleturquoise 		{ color:#afeeee !important}
.text-palevioletred 		{ color:#d87093 !important}
.text-papayawhip 			{ color:#ffefd5 !important}
.text-peachpuff 			{ color:#ffdab9 !important}
.text-peru 					{ color:#cd853f !important}
.text-pink 					{ color:#ffc0cb !important}
.text-plum 					{ color:#dda0dd !important}
.text-powderblue 			{ color:#b0e0e6 !important}
.text-purple 				{ color:#800080 !important}
.text-red 					{ color:#ff0000 !important}
.text-rosybrown 			{ color:#bc8f8f !important}
.text-royalblue 			{ color:#4169e1 !important}
.text-saddlebrown 			{ color:#8b4513 !important}
.text-salmon 				{ color:#fa8072 !important}
.text-sandybrown 			{ color:#f4a460 !important}
.text-seagreen 				{ color:#2e8b57 !important}
.text-seashell 				{ color:#fff5ee !important}
.text-sienna 				{ color:#a0522d !important}
.text-silver 				{ color:#c0c0c0 !important}
.text-skyblue 				{ color:#87ceeb !important}
.text-slateblue 			{ color:#6a5acd !important}
.text-slategray 			{ color:#708090 !important}
.text-slategrey 			{ color:#708090 !important}
.text-snow 					{ color:#fffafa !important}
.text-springgreen 			{ color:#00ff7f !important}
.text-steelblue 			{ color:#4682b4 !important}
.text-tan 					{ color:#d2b48c !important}
.text-teal 					{ color:#008080 !important}
.text-thistle 				{ color:#d8bfd8 !important}
.text-tomato 				{ color:#ff6347 !important}
.text-turquoise 			{ color:#40e0d0 !important}
.text-violet 				{ color:#ee82ee !important}
.text-wheat 				{ color:#f5deb3 !important}
.text-white 				{ color:#ffffff !important}
.text-whitesmoke 			{ color:#f5f5f5 !important}
.text-yellow 				{ color:#ffff00 !important}
.text-yellowgreen 			{ color:#9acd32 !important}
.text-forest-green 			{ color:#228B22 !important}
.text-boo-green				{ color:#77A170 !important}
.text-black-pearl			{ color:#152934 !important}
.text-midnight-express		{ color:#1E2430 !important}
.text-speech-red    		{ color:#d50000 !important}

.text-warning 				{ color:@accent-color;}
.text-important 			{ color:#B94A48;}
