// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// IMPORT BOOTSTRAP MIXINS
// --------------------------------------------------

.validation-summary-errors {
	border: 1px solid #FF0000;
	background-color: #F7F7F7;
	padding: 2px;
	margin: 5px 5px 10px 5px;
	.border-radius(5px);
	.background-clip(padding-box);
}

.validation-summary-errors ul {
	margin-top: 5px;
	margin-bottom: 5px;
	list-style-type: disc;
}

.validation-summary-errors li {
	margin-bottom: 2px;
	color: #CC0000;
	font-style: italic;
}

.validation-summary-errors strong {
	color: #222;
	font-weight: bold;
}


// jQuery invalid states
input.ng-dirty.ng-invalid,
textarea.ng-dirty.ng-invalid,
dx-text-box.ng-dirty.ng-invalid,
dx-text-area.ng-dirty.ng-invalid,
select.ng-dirty.ng-invalid,
dx-date-box.ng-dirty.ng-invalid,
app-phx-date-box.ng-dirty.ng-invalid dx-date-box,
app-phx-select-box.ng-dirty.ng-invalid dx-select-box,
.show-error {
    border-color: @error-border-color !important;

    &:focus {
        color: @error-focus-text-color !important;
        border-color: #f38c89 !important;
        @shadow: 0 0 6px #f8b9b7;
        .box-shadow-important(@shadow);
    }

    option {
        color: #555;
    }
}

/* required label tooltip */
.req-lab-left {
	background: url(../assets/images/tooltip/icon-required.png) left 3px no-repeat;
	padding-left: 16px !important;
	border-bottom: 0 dashed #E0713B;
}

.req-lab-right {
	background: url(../assets/images/tooltip/icon-required.png) right 3px no-repeat;
	padding-right: 16px !important;
	border-bottom: 0 dashed #E0713B;
}

.req-lab-left-valid {
	padding-left: 16px !important;
	border-bottom: 0 dashed #E0713B;
}

.req-lab-right-valid, .noreq-lab {
	padding-right: 16px !important;
	border-bottom: 0 dashed #E0713B;
}

.editFormTableViewMode .req-lab-left, .editFormTableViewMode .req-lab-right {
	background-image: none !important;
}

.error-box {
	margin: 15px;
}
