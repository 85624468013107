.btn {
  transition: all 0.3s;

  &.btn-default {
    background-color: var(--fbo-theme-default-button-background, #fff);
    border-color: var(--fbo-theme-default-button-border-color, #bdc5d5);
    color: var(--fbo-theme-default-button-text-color, #1a203f);

    &:hover {
      color: var(--fbo-theme-default-button-text-hover-color, #1f53af);
      background-color: var(--fbo-theme-default-button-hover-background-color, #1e64e11a);
    }
  }

  &.btn-secondary {
    border-color: var(--fbo-theme-secondary-button-border, #1b5acb);
    color: var(--fbo-theme-secondary-button-color, #1b5acb);

    &:hover {
      color: var(--fbo-theme-secondary-button-hover-color, #1f53af);
      background-color: var(--fbo-theme-secondary-button-hover-background, #1e64e11a);
    }
  }

  &.btn-warning {
    color: var(--fbo-theme-warning-button-color, #fff);
    background-color: var(--fbo-theme-warning-button-background, #f0ad4e);
    border-color: var(--fbo-theme-warning-button-border, #eea236);
    &:hover {
      color: var(--fbo-theme-warning-button-hover-color, #fff);
      background-color: var(--fbo-theme-warning-button-hover-background, #fab55c);
    }
  }

  &.btn-primary {
    background-color: var(--fbo-theme-primary-button-background, #1e64e1);
    border-color: var(--fbo-theme-primary-button-border, #1b5acb);
    color: var(--fbo-theme-primary-button-color, #fff);

    &:hover {
      color: var(--fbo-theme-primary-button-hover-color, #fff);
      background-color: var(--fbo-theme-primary-button-hover-background, #255cbd);
    }
  }

  &.btn-danger {
    color: var(--fbo-theme-danger-button-color, #fff);
    background-color: var(--fbo-theme-danger-button-background, #d9534f);
    border-color: var(--fbo-theme-danger-button-border, #d43f3a);

    &:hover {
      color: var(--fbo-theme-danger-button-color, #fff);
      background-color: var(--fbo-theme-danger-button-hover-background, #e55c59);
    }
  }
}

.modal-footer,
.modal-header {
  width: 100%;
}
