// Core variables and mixins
@import "variables.less";
@import "mixins.less";

//
// Button groups
// --------------------------------------------------
.caret {
  .accent & {
    border-top-color: @brand-primary-accent;
  }

}

.dropup .caret {
  .accent & {
    border-bottom-color: @brand-primary-accent;
  }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  .btn-group {
    display: inline-block;
    float: none;
  }

  &.button-bar {
    .btn-group {
      float: right;

      button {
        margin-left: 5px;
      }
    }
  }

  > .btn,
  > .btn-group {
    + .btn,
    + .btn-group {
      margin-left: 5px;
    }
  }
}
