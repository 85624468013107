// Data grid column selector button

.dx-button-mode-contained {
  transition: all 0.3s;
  background-color: var(--fbo-theme-default-button-background, #fff);
  border-color: var(--fbo-theme-default-button-border-color, #bdc5d5);
  .dx-icon {
    color: var(--fbo-theme-default-button-text-color, #1a203f);
  }

  &:hover {
    background-color: var(--fbo-theme-default-button-hover-background-color, #1e64e11a);

    .dx-icon {
      color: var(--fbo-theme-default-button-text-hover-color, #1f53af);
    }
  }
}

/** Popover container and arrow */
.entity-version-select {
  &.dx-popover-wrapper .dx-popover-arrow::after,
  &.dx-popover-wrapper.dx-popover-without-title .dx-popover-arrow::after,
  &.dx-popup-wrapper > .dx-overlay-content {
    background-color: #f7f7f7;
  }
}


/** Overrides for timesheet capsule dropdown buttons */
app-time-sheet-line-management-capsule {

  .dx-dropdownbutton:not(.dx-dropdownbutton-has-arrow) .dx-button-has-icon:not(.dx-button-has-text) .dx-button-content {
    padding: 0;
  }

  .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-hover {
    background-color: transparent;
  }

  .dx-buttongroup-item.dx-button.dx-button-mode-outlined,
  .dx-buttongroup-item.dx-button.dx-button-mode-outlined.dx-state-focused {
    background-color: transparent;
    border: none;
  }

  .dx-icon.dx-icon-more {
    transform: rotate(90deg);
  }
}

.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 0;
}

.dx-widget {
  font-family: 'Open Sans', sans-serif;
}

/** Overrides for activity centre sort buttons */
.app-activity-centre-sort-menu {
  a.dropdown-item {
    font-family: 'Open Sans', sans-serif;
    display: block;
    white-space: nowrap;
    color: #000;
    background-color: transparent;
    padding: 8px 20px;
    cursor: pointer;

    &:not(:first-of-type) {
      border-top: 1px solid #eee;
    }

    &:hover {
      background-color: #f5f5f5;
      text-decoration: none;
    }

    &.active {
      font-weight: 600;
    }
  }
}

/** Overrides for phx data table, select view dropdown button */
app-phx-data-table {
  .apply-view-dropdown .dx-button-content {
    height: 36px;
  }

  .apply-view-dropdown .dx-button-has-text .dx-button-content {
    font-weight: 600;
    font-size: 13px;
    padding: 6px 12px !important;
  }
}

.dx-dropdownbutton-popup-wrapper.dx-popup-wrapper .dx-overlay-content {
  border-top-width: 1px;
}

/** Adjust padding for dropdown with badge */
app-phx-select-box-badge {
  app-phx-select-box {
    .dx-texteditor {
      padding: 0;
    }
  }
}

/** Setting height for inner select box to avoid ng-deep */
app-phx-select-box {
  dx-select-box {
    height: 34px;
  }
}
