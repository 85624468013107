// Core variables and mixins
@import "variables.less";
@import "mixins.less";

//
// Layouts
// --------------------------------------------------


.app-col.main-sidebar {
    //left: 0;
    width: @sidebar-width;
}

.container-sidebar {
    .nav-pills {
        > li > a {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

.rightbar {
    &:before { 
        content: "";
        width: 315px;
        position: fixed;
        top: 94px;
        bottom: 0;
        border: 1px solid #E5E5E5; //1px solid @sidebar-border-right;
        border-width: 0 1px 0 1px;
    }
}
