/* https://github.com/FontFaceKit/open-sans */

/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/Light/OpenSans-Light.eot?v=1.1.0");
  src: url("/assets/font/open-sans/Light/OpenSans-Light.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/Light/OpenSans-Light.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/Light/OpenSans-Light.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/Light/OpenSans-Light.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/Light/OpenSans-Light.svg?v=1.1.0#Light") format("svg");
  font-weight: 300;
  font-style: normal; }
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/LightItalic/OpenSans-LightItalic.eot?v=1.1.0");
  src: url("/assets/font/open-sans/LightItalic/OpenSans-LightItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/LightItalic/OpenSans-LightItalic.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/LightItalic/OpenSans-LightItalic.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/LightItalic/OpenSans-LightItalic.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/LightItalic/OpenSans-LightItalic.svg?v=1.1.0#LightItalic") format("svg");
  font-weight: 300;
  font-style: italic; }
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/Regular/OpenSans-Regular.eot?v=1.1.0");
  src: url("/assets/font/open-sans/Regular/OpenSans-Regular.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/Regular/OpenSans-Regular.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/Regular/OpenSans-Regular.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/Regular/OpenSans-Regular.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/Regular/OpenSans-Regular.svg?v=1.1.0#Regular") format("svg");
  font-weight: normal;
  font-style: normal; }
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/Italic/OpenSans-Italic.eot?v=1.1.0");
  src: url("/assets/font/open-sans/Italic/OpenSans-Italic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/Italic/OpenSans-Italic.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/Italic/OpenSans-Italic.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/Italic/OpenSans-Italic.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/Italic/OpenSans-Italic.svg?v=1.1.0#Italic") format("svg");
  font-weight: normal;
  font-style: italic; }
/* END Italic */
/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/Semibold/OpenSans-Semibold.eot?v=1.1.0");
  src: url("/assets/font/open-sans/Semibold/OpenSans-Semibold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/Semibold/OpenSans-Semibold.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/Semibold/OpenSans-Semibold.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/Semibold/OpenSans-Semibold.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/Semibold/OpenSans-Semibold.svg?v=1.1.0#Semibold") format("svg");
  font-weight: 600;
  font-style: normal; }
/* END Semibold */
/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/SemiboldItalic/OpenSans-SemiboldItalic.eot?v=1.1.0");
  src: url("/assets/font/open-sans/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/SemiboldItalic/OpenSans-SemiboldItalic.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/SemiboldItalic/OpenSans-SemiboldItalic.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/SemiboldItalic/OpenSans-SemiboldItalic.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/SemiboldItalic/OpenSans-SemiboldItalic.svg?v=1.1.0#SemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic; }
/* END Semibold Italic */
/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/Bold/OpenSans-Bold.eot?v=1.1.0");
  src: url("/assets/font/open-sans/Bold/OpenSans-Bold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/Bold/OpenSans-Bold.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/Bold/OpenSans-Bold.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/Bold/OpenSans-Bold.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/Bold/OpenSans-Bold.svg?v=1.1.0#Bold") format("svg");
  font-weight: bold;
  font-style: normal; }
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/BoldItalic/OpenSans-BoldItalic.eot?v=1.1.0");
  src: url("/assets/font/open-sans/BoldItalic/OpenSans-BoldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/BoldItalic/OpenSans-BoldItalic.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/BoldItalic/OpenSans-BoldItalic.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/BoldItalic/OpenSans-BoldItalic.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/BoldItalic/OpenSans-BoldItalic.svg?v=1.1.0#BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic; }
/* END Bold Italic */
/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/ExtraBold/OpenSans-ExtraBold.eot?v=1.1.0");
  src: url("/assets/font/open-sans/ExtraBold/OpenSans-ExtraBold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/ExtraBold/OpenSans-ExtraBold.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/ExtraBold/OpenSans-ExtraBold.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/ExtraBold/OpenSans-ExtraBold.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/ExtraBold/OpenSans-ExtraBold.svg?v=1.1.0#ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal; }
/* END Extrabold */
/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("/assets/font/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?v=1.1.0");
  src: url("/assets/font/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("/assets/font/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff2?v=1.1.0") format("woff2"), url("/assets/font/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff?v=1.1.0") format("woff"), url("/assets/font/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.ttf?v=1.1.0") format("truetype"), url("/assets/font/open-sans/ExtraBoldItalic/OpenSans-ExtraBoldItalic.svg?v=1.1.0#ExtraBoldItalic") format("svg");
  font-weight: 800;
  font-style: italic; }
/* END Extrabold Italic */

/* https://google-webfonts-helper.herokuapp.com/fonts/ibm-plex-sans?subsets=latin */

/* ibm-plex-sans-100 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Thin'), local('IBMPlexSans-Thin'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-200 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans ExtraLight'), local('IBMPlexSans-ExtraLight'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-100italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Thin Italic'), local('IBMPlexSans-ThinItalic'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-100italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-200italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans ExtraLight Italic'), local('IBMPlexSans-ExtraLightItalic'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-200italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-300 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-300italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-300italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-500italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Medium Italic'), local('IBMPlexSans-MediumItalic'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-500 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Medium'), local('IBMPlexSans-Medium'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-600italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans SemiBold Italic'), local('IBMPlexSans-SemiBoldItalic'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-600italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-700italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans Bold Italic'), local('IBMPlexSans-BoldItalic'),
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/ibm-plex-sans/ibm-plex-sans-v3-latin-700italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}