@import "variables";

.st-sort-ascent:before {
    content: '\25B2\00a0';
}

.st-sort-descent:before {
    content: '\25BC\00a0';
}


.align-right {
    text-align:right;
}

.align-right-md {
    @media screen and (max-width: @screen-md) {
        text-align: right;
    }
}


.table-layout-fixed {
    table-layout: fixed;
}

@-webkit-keyframes halfspin {
    to {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@-moz-keyframes halfspin {
    to {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes halfspin {
    to {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    color: #000;
    background-color: #dfdfdf;
    cursor:pointer;
    
}


.stAdvancedSearch.menu {
    position: relative;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .stAdvancedSearch.menu.last-advance-search{
         .menu-dropdown{
              right:0;
              left:initial;
         }       
    }  
}
@-moz-document url-prefix() {
    .stAdvancedSearch.menu.last-advance-search{
         .menu-dropdown{
              right:0;
              left:initial;
         }       
    }
}
.stAdvancedSearch {
    .menu-dropdown {
        list-style: none;
        background: #fff;
        position: absolute;
        min-width: 150px;
        max-height: 400px;
        margin-top: 10px;
        padding: 0px;
        left: 0;
        top: 25px;
        z-index: 99;
        overflow-y: auto !important;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        -webkit-box-shadow: 0 2px 6px #bbb !important;
        -moz-box-shadow: 0 2px 6px #bbb !important;
        box-shadow: 0 2px 6px #bbb !important;
        border-collapse: separate;
        padding-bottom: 8px !important;

        .btn-success {
            margin-right: 5px;
            margin-bottom: 5px;
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .badge.badge-info {
            cursor: pointer;
        }

        ul.checked-list-box li {
            input {
                cursor: pointer;
            }

            span.lbl {
                cursor: pointer;
            }
        }

        .menu-header, .menu-footer {
            padding-right: 10px;
        }

        .menu-header {
            span {
                padding-bottom: 5px;
                margin-right: 5px;
            }
        }
    }

        .menu-dropdown, .menu-dropdown tbody {
            height: auto !important;
            overflow-y: hidden !important;
        }

            .stOpenColumnEditor, .menu-dropdown, .menu-dropdown tbody {
                height: auto !important;
                overflow-y: auto !important;
            }

        &.menu-dropdown-wide180 .menu-dropdown {
            min-width: 180px;
        }


    .menu-header {
        padding: 8px;
        display: block;
        background: #f6f6f6;
        border-bottom: 1px solid #ddd;
    }

    .menu-content {
        padding: 8px;
        display: block;

        input[datepicker-popup] {
            width: 160px;
        }

        > * {
            margin-bottom: 4px;
        }
    }

    ul.list-group {
        list-style: none;
        margin: 0;
        padding: 0;

        li.list-group-item {
            border: none;
            padding: 0px;

            span {
                padding: 0px 10px;
            }
        }


            li.list-group-item:hover {

                .lbl {
                    font-weight: 700;
                }
            }
    }
}

table.fixed-columns {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: stretch;

    * {
        -moz-box-sizing: inherit;
        -webkit-box-sizing: inherit;
        box-sizing: inherit;
    }

    thead {
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        align-items: stretch;
    }

    th, tbody td {
        width: 20%; /* this can vary */
        overflow-x: hidden;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: inline-block;
    }

    tbody {
        display: inline-block;
        overflow-y: auto;
    }

    > thead > tr, > tbody > tr, > tfoot > tr {
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    thead, tfoot {
        flex-shrink: 0;
    }
}
