// Core variables and mixins
@import "variables.less";
@import "mixins.less";

//
// Panes system
// --------------------------------------------------

/* Generic pane rules */
.app-page {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.app-row,
.app-col,
.app-page {
    position: absolute;
    overflow: hidden;
}

.app-row {
    right: 0;
    left: 0;
    top: 50px;
    bottom: 0px;
}

.app-col {
    top: 0;
    bottom: 0;
}

.app-fill, 
.app-pane {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.app-pane {
    display: none;

    &.active {
        display: block;
    }
}
 
.scroll-x {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.scroll-y {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


/* Workaround: in landscape mode, IEMobile makes bottom toolbar overlap the page, so shorten the page by 75px to compensate */
.iemobile .app-page {
    bottom: -6px;
}

@media screen and (orientation:landscape) {
    .iemobile .app-page {
        bottom: 75px;
    }
}



