@import './variables.less';

.cdk-global-scrollblock {
  overflow-y: auto;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.5;
}

// Modals in fbo are not in the middle of the screen, we need to accommodate for that
.cdk-global-overlay-wrapper > .modal-dialog {
  align-self: start;
  overflow: auto;
}

.bottom-file-uploader {
  position: absolute !important;
  bottom: 0;
  left: @sidebar-width;
  padding-right: @sidebar-width;
  @media screen and (max-width: 1279px) {
    left: 0;
    padding-right: 0;
  }
}

.cdk-overlay-pane.modal-dialog {
  &.modal-sm {
    width: 600px;
  }
}

.modal-dialog {
  .cdk-dialog-container {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    background-color: #fff;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes flyIn {
  from {
    transform: translateY(-25%);
  }
  to {
    transform: translate(0);
  }
}

.cdk-overlay-container {
  opacity: 0;
  animation: fadeIn 0.3s forwards;

  cdk-dialog-container {
    transform: translateY(-25%);
    animation: flyIn 0.5s forwards;
  }
}

.mat-mdc-form-field-infix {
  min-height: unset;
}

app-global-search {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0.5em 0;
}

// FBO does not round the borders in inputs
.mdc-text-field,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 0 !important;
}

// FBO uses a different border color
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #dcdcdc!important;
}

// FBO uses a different border color for hovers
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgb(220, 220, 220)!important;
}

// FBO uses 13px font size, instead of 16px
.mat-mdc-form-field,
.mat-mdc-floating-label,
.mat-mdc-input-element {
  font-size: 13px!important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  font-size: 150%;
  line-height: 1.125;
  padding: 0 !important;
}

// Not to overwrite the styling from FBO
.mdc-list-item__primary-text {
  color: unset !important;
  font-size: 14px !important;
}

// This used to be flex and FBO has been relying on that in dropdowns in az-search
.mat-mdc-list-item-unscoped-content {
  display: flex;
  align-items: center;
}

// Prevent round ripple on icons when hovering
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  background-color: transparent !important;
}

// FBO uses blue for selected checkboxes, not purple as material
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background,
.mat-mdc-checkbox.mat-mdc-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
// And for items selected in a list
.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
  border-color: #1e64e1 !important;
  background: #1e64e1 !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  border-top: 1px solid #dcdcdc !important;
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  font-size: 13px;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  overflow: visible;
  color: #0009;
  mat-label:not(:empty) {
    background-color: white;
    box-sizing: border-box;
    padding: 0 5px;
  }
}
app-clearable-input {
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}
.material-icons {
  font-size: 20px;

  &.mat-icon {
    width: 20px;
    height: 20px;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 8px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--fbo-theme-accent-color, #2f3948);
}

/** Mail campaign stepper uses custom panel */
app-mail-stepper {
  mat-stepper {
    .mat-horizontal-stepper-header-container {
      display: none;
    }
  }
}

.mat-mini-fab.mat-primary {
  background-color: var(--fbo-theme-default-button-background, #fff);
  border-color: var(--fbo-theme-default-button-border-color, #bdc5d5);
  color: var(--fbo-theme-default-button-text-color, #1a203f);

  &:not(:disabled):hover {
    color: var(--fbo-theme-default-button-text-hover-color, #1f53af);
    background-color: var(--fbo-theme-default-button-hover-background-color, #1e64e11a);
  }
}

.mat-mini-fab.mat-accent {
  background-color: var(--fbo-theme-accent-color, #f8b91e);
}

mat-stepper .mat-horizontal-content-container {
  padding: 0;
}

@media screen and (max-width: @screen-sm) {
  mat-stepper .mat-horizontal-content-container {
    padding: 0 0 24px 0;
  }
}

app-stepper-header {
  .mat-mini-fab .mat-button-wrapper {
    padding: 0;
  }
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #1e64e1;
  --mdc-switch-selected-handle-color: #1e64e1;
  --mdc-switch-selected-hover-state-layer-color: #1e64e1;
  --mdc-switch-selected-pressed-state-layer-color: #1e64e1;
  --mdc-switch-selected-focus-handle-color: #1e64e1;
  --mdc-switch-selected-hover-handle-color: #1e64e1;
  --mdc-switch-selected-pressed-handle-color: #1e64e1;
  --mdc-switch-selected-focus-track-color: #1e64e1;
  --mdc-switch-selected-hover-track-color: #1e64e1;
  --mdc-switch-selected-pressed-track-color: #1e64e1;
  --mdc-switch-selected-track-color: #1e64e1;
}

.mdc-form-field > label {
  font-weight: normal;
  // To counter changes from forms.less @ line 34
  margin-bottom: 0;
}

// To prevent misalignment on Timesheet Validation page in transaction duplicates
.mat-mdc-tab-link.mdc-tab {
  flex-grow: 0!important;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #1e64e1; 
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #1e64e1;
}

.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1e64e1;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}