// Core variables and mixins
@import "variables.less";
@import "mixins.less";

/** accordion */
.accordion-style1.panel-group {
    .panel {
        border-radius: 0;
        border-color: @accordion-border;
        background-color: #FFF;
        box-shadow: none;

        &:last-child {
            border-bottom-width: 1px;
        }

        .collapse {
            background-color: #FFF;
        }

        + .panel {
            margin-top: 2px;
        }
    }

    .panel-heading + .panel-collapse .panel-body {
        border-top-color: @accordion-border !important;
    }

    .panel-heading {
        padding: 0;
    }

        .panel-heading .accordion-toggle {
            color: @tab-hover-color;
            background-color: @accordion-active-bg;
            position: relative;
            font-weight: bold;
            font-size: 13px;
            line-height: 1;
            padding: 10px;
            display: block;

            &.collapsed {
                color: @accordion-header-text;
                font-weight: normal;
                background-color: #F9F9F9;
            }

            &:hover {
                color: @accordion-header-text-hover;
                background-color: @accordion-header-hover-bg;
                text-decoration: none;
            }

            &:focus, &:active {
                outline: none;
                text-decoration: none;
            }


            > [class*="icon-"]:first-child {
                width: 16px;
            }

            &:hover > [class*="icon-"]:first-child {
                text-decoration: none;
            }
        }


    .panel-body, .collapse.in > .panel-body {
        border-top: 1px solid @accordion-border;
    }

    &.no-padding {
        padding: 0;
    }
}

//style2, used in faq, etc...
.accordion-style2.panel-group {
    .panel {
        border-width: 0;

        &:last-child {
            border-bottom-width: 0;
        }

        + .panel {
            margin-top: 4px;
        }

        .panel-body {
            border-top: none;
        }
    }

    .panel-heading .accordion-toggle {
        background-color: #EDF3F7;
        border: 2px solid #6EAED1;
        border-width: 0 0 0 2px;

        &:hover {
            text-decoration: none;
        }

        &.collapsed {
            background-color: #F3F3F3;
            color: #606060;
            border-width: 0 0 0 1px;
            border-color: #D9D9D9;

            &:hover {
                background-color: #F6F6F6;
                color: #438EB9;
                text-decoration: none;
            }
        }
    }

    .panel-body, .collapse.in > .panel-body {
        border-top: none;
    }
}

    /* nested questions */
    .accordion-style2.panel-group .accordion-style2.panel-group {
        .panel {
            border-bottom: 1px dotted #D9D9D9;

            &:last-child {
                border-bottom: none;
            }

            .panel-heading, .panel-heading .accordion-toggle {
                background-color: transparent;
                border-width: 0;
                font-size: 13px;
                padding-top: 6px;
                padding-bottom: 8px;
            }

            .panel-heading {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
